import React from "react";
import { MenuItem, Menu, TableRow, Card } from "@mui/material";
import { styled } from "@mui/material/styles";
import { StyledTableBody, UXDataTableWithoutBody, StyledTableCell } from "../../../../components/UXDataTable";
import { SDSRequestContextProvider } from "../../Context/ProcessSDS";
import MoreOptions from "@mui/icons-material/MoreVert";
import { convertDateFormat } from "../../../../global";
import ModalSimpleButton from "../../../../components/Modal/ModalSimpleButton";
import SubmitSDS from "../SubmitSDS";
import Requests from "../../../../api/SDSRequest/SDSRequest";
import toast from "react-hot-toast";

const StyledTableCellClickable = styled(StyledTableCell)(() => ({
  cursor: 'pointer'
}));


const completedStatus = 'Complete';
const cancelledStatus = 'Cancelled';

export function GridRender(props) {
  const { showCompletedRequests, showCancelledRequests, requests, loading, canEdit, gridColumns, currentUser } = props;
  const filteredEvents = [];

  requests && requests.forEach((row) => {
    filteredEvents.push(row);
  });

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [moreOptionsSelected, setMoreOptionsSelected] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [modalMessagesOpen, setModalMessagesOpen] = React.useState(false);
  const [modalMessageText, setModalMessageText] = React.useState('');
  const [modalMessagesTitle, setModalMessagesTitle] = React.useState('');
  const [modalMessagesButtonText, setModalMessagesButtonText] = React.useState('Ok');
  const [selectedRequest, setSelectedRequest] = React.useState(null);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('requestStatusName');
  const [menuLoading, setMenuLoading] = React.useState(false);

  React.useEffect(() => {
    setSelectedRequest()
  }, [showCompletedRequests, showCancelledRequests]);

  React.useEffect(() => {
    if (requests?.length) {
      setOrderBy('requestStatusName');
      setOrder('desc');
    }
  }, [requests]);

  function reloadData() {
    props.reloadList();
    setSelectedRequest();
  }

  const handlePageChange = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const determineStartArray = () => {
    return stableSort(filteredEvents, getComparator(order, orderBy))
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };

  function handleOpenMoreOptions(e, workRequest) {
    setMoreOptionsSelected(workRequest)
    setAnchorEl(e.currentTarget)
  }

  function handleCloseMoreOptions() {
    setMoreOptionsSelected(null)
    setAnchorEl(null)
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessageText('')
    setModalMessagesButtonText('Ok')
    setModalMessagesTitle('')
  }

  async function updateRequestStatus(request) {
    setMenuLoading(true);
    handleCloseMoreOptions(null);
    const data = await Requests.updateSDSRequestStatus(request.id, "Cancelled");
    if (data) {
      toast.success(`Request Id ${request.id} cancelled successfuly`);
      reloadData();
    } else {
      toast.error("An error ocurred while trying to update the request");
    }
    setMenuLoading(false);
  }

  async function updateRequestAssignedTo(request) {
    setMenuLoading(true);
    handleCloseMoreOptions(null);
    const data = await Requests.updateSDSRequestAssignedTo(request.id, currentUser.username);
    if (data) {
      toast.success(`Request Id ${request.id} cancelled successfuly`);
      reloadData();
    } else {
      toast.error("An error ocurred while trying to update the request");
    }
    setMenuLoading(false);
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function descendingComparator(a, b, orderBy) {
    if (orderBy === 'requestStatusName') {
      const orderMap = {
        'InProgress': 1,
        'Assigned': 2,
        'Submitted': 3,
        'OnHold': 4
      };
      const aOrder = orderMap[a[orderBy]] || 5;
      const bOrder = orderMap[b[orderBy]] || 5;
      return aOrder - bOrder;
    }

    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  return (
    <SDSRequestContextProvider>
      <UXDataTableWithoutBody
        tableWidth="100%"
        cols={gridColumns}
        tableId="gridSdsRequests"
        rowLength={filteredEvents.length}
        enablePaging={true}
        enableSorteable={true}
        order={order}
        orderBy={orderBy}
        handleRequestSort={handleRequestSort}
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handlePageChange}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        noDataFoundMessage={"No data found"}
        isDataLoading={loading}
      >
        <StyledTableBody>
          {determineStartArray().map((row, index) => {
            return (
              <TableRow hover key={index}>
                <StyledTableCell component="th" scope="row" style={{ width: "10px" }}>
                  <MoreOptions onClick={(e) => handleOpenMoreOptions(e, row)} color="black" />
                </StyledTableCell>
                <StyledTableCellClickable onClick={() => { setSelectedRequest(row) }}> {row?.id} </StyledTableCellClickable>
                <StyledTableCellClickable onClick={() => { setSelectedRequest(row) }}> {row?.requesterByEmail} </StyledTableCellClickable>
                <StyledTableCellClickable onClick={() => { setSelectedRequest(row) }}> {row?.sdsRequestType.description} </StyledTableCellClickable>
                <StyledTableCellClickable onClick={() => { setSelectedRequest(row) }}> {convertDateFormat(row?.createdDate)} </StyledTableCellClickable>
                <StyledTableCellClickable onClick={() => { setSelectedRequest(row) }}> {convertDateFormat(row?.requestedCompletionDate)} </StyledTableCellClickable>
                <StyledTableCellClickable onClick={() => { setSelectedRequest(row) }}> {row?.requestStatusName} </StyledTableCellClickable>
                <StyledTableCellClickable onClick={() => { setSelectedRequest(row) }}> {row?.project?.name} </StyledTableCellClickable>
                <StyledTableCellClickable onClick={() => { setSelectedRequest(row) }}> {row?.description} </StyledTableCellClickable>
              </TableRow>
            )
          })}
        </StyledTableBody>
      </UXDataTableWithoutBody>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={anchorEl && moreOptionsSelected ? true : false}
        onClose={() => handleCloseMoreOptions(null)}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
      >
        <MenuItem
          key="mnuCancel"
          onClick={() => { updateRequestStatus(moreOptionsSelected) }}
          disabled={!canEdit || menuLoading}
        >
          Cancel {menuLoading && <span>. Please wait...</span>}
        </MenuItem>
        <MenuItem
          key="mnuAssignToMe"
          onClick={() => { updateRequestAssignedTo(moreOptionsSelected) }}
          disabled={!canEdit || menuLoading}
        >
          Assign to me {menuLoading && <span>. Please wait...</span>}
        </MenuItem>
      </Menu>

      <ModalSimpleButton
        title={modalMessagesTitle}
        buttonText={modalMessagesButtonText}
        buttonAction={closeModalMessages}
        open={modalMessagesOpen}
        setOpen={setModalMessagesOpen}
      >
        <label>
          {modalMessageText}
        </label>
      </ModalSimpleButton>

      {selectedRequest &&
        <Card variant="elevation" style={{ padding: "1rem", marginTop: "2rem" }}>
          <SubmitSDS
            canEdit={canEdit && selectedRequest.requestStatusName !== completedStatus && selectedRequest.requestStatusName !== cancelledStatus}
            reloadList={reloadData}
            request={selectedRequest}
          />
        </Card>
      }

    </SDSRequestContextProvider>
  );

}