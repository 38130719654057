import { checkAPIResponse } from "../helpers";
import API from "../index";

export default class Item {
  constructor(
    ID,
    CreatedDate,
    CreatedByEmail,
    LastModifiedDate,
    LastModifiedByEmail,
    IsCapitalAsset,
    CapitalizedDate,
    ChargeCode,
    Comments,
    CompanyCode,
    ContactEmail,
    CostCenter,
    DeactivationDate,
    Description,
    InventoryLastVerifiedByEmail,
    InventoryLastDate,
    InventoryNote,
    Manufacturer,
    OriginalAssetNumber,
    ProfitCenter,
    SAPAssetNumber,
    SAPAssetNumberSplit,
    SerialNumber,
    SiteAssetNumber,
    StorageAvailability,
    Weight,
    WeightUoM,
    AlertQuantity,
    IsDeliverable,
    EquipmentTypeName,
    EquipmentPriceUSD,
    Quantity,
    ReorderQuantity,
    ReorderPriceUSD,
    IsAutomaticReorder,
    ReorderUoM,
    IsSignatureRequired,
    StockUnitOfMeasure,
    SUINRU,
    VendorStockNumber,
    ModelNumber,
    StockroomCategoryName,
    OwnerEmail,
    Connection,
    DeviceType,
    ElastomerType,
    InletGaugeRange,
    InspectionFrequency,
    MaxInletPressure,
    Mixture,
    OutletGaugeRange,
    OutletPressure,
    IsRecyclable,
    ReplacementDate,
    RestrictiveOrifice,
    Service,
    SetPressure,
    SheetNumber,
    TagName,
    EquipmentStatusName,
    SampleName,
    VendorID,
    ItemVendor,
    SiteName,
    LocationName,
    ImagePath,
    SubLocation,
    ReorderPoint,
    IsActive,
    OpenPoItemsCount,
  ) {
    this.ID = ID;
    this.CreatedDate = CreatedDate;
    this.CreatedByEmail = CreatedByEmail;
    this.LastModifiedDate = LastModifiedDate;
    this.LastModifiedByEmail = LastModifiedByEmail;
    this.IsCapitalAsset = IsCapitalAsset;
    this.CapitalizedDate = CapitalizedDate;
    this.ChargeCode = ChargeCode;
    this.Comments = Comments;
    this.CompanyCode = CompanyCode;
    this.ContactEmail = ContactEmail;
    this.CostCenter = CostCenter;
    this.DeactivationDate = DeactivationDate;
    this.Description = Description;
    this.InventoryLastVerifiedByEmail = InventoryLastVerifiedByEmail;
    this.InventoryLastDate = InventoryLastDate;
    this.InventoryNote = InventoryNote;
    this.Manufacturer = Manufacturer;
    this.OriginalAssetNumber = OriginalAssetNumber;
    this.ProfitCenter = ProfitCenter;
    this.SAPAssetNumber = SAPAssetNumber;
    this.SAPAssetNumberSplit = SAPAssetNumberSplit;
    this.SerialNumber = SerialNumber;
    this.SiteAssetNumber = SiteAssetNumber;
    this.StorageAvailability = StorageAvailability;
    this.Weight = Weight;
    this.WeightUoM = WeightUoM;
    this.AlertQuantity = AlertQuantity;
    this.IsDeliverable = IsDeliverable;
    this.EquipmentTypeName = EquipmentTypeName;
    this.EquipmentPriceUSD = EquipmentPriceUSD;
    this.Quantity = Quantity;
    this.ReorderQuantity = ReorderQuantity;
    this.ReorderPriceUSD = ReorderPriceUSD;
    this.IsAutomaticReorder = IsAutomaticReorder;
    this.ReorderUoM = ReorderUoM;
    this.IsSignatureRequired = IsSignatureRequired;
    this.StockUnitOfMeasure = StockUnitOfMeasure;
    this.SUINRU = SUINRU;
    this.VendorStockNumber = VendorStockNumber;
    this.ModelNumber = ModelNumber;
    this.StockroomCategoryName = StockroomCategoryName;
    this.OwnerEmail = OwnerEmail;
    this.Connection = Connection;
    this.DeviceType = DeviceType;
    this.ElastomerType = ElastomerType;
    this.InletGaugeRange = InletGaugeRange;
    this.InspectionFrequency = InspectionFrequency;
    this.MaxInletPressure = MaxInletPressure;
    this.Mixture = Mixture;
    this.OutletGaugeRange = OutletGaugeRange;
    this.OutletPressure = OutletPressure;
    this.IsRecyclable = IsRecyclable;
    this.ReplacementDate = ReplacementDate;
    this.RestrictiveOrifice = RestrictiveOrifice;
    this.Service = Service;
    this.SetPressure = SetPressure;
    this.SheetNumber = SheetNumber;
    this.TagName = TagName;
    this.EquipmentStatusName = EquipmentStatusName;
    this.SampleName = SampleName;
    this.VendorID = VendorID;
    this.ItemVendor = ItemVendor;
    this.SiteName = SiteName;
    this.LocationName = LocationName;
    this.ImagePath = ImagePath;
    this.SubLocation = SubLocation;
    this.ReorderPoint = ReorderPoint;
    this.IsActive = IsActive;
    this.OpenPoItemsCount = OpenPoItemsCount;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get("/stockroomitem");
    return data?.result?.map((item) => new Item(item));
  }

  static async getItemByAssetNumber(assetNumber) {
    const api = await API();
    const { data } = await api.get(`/stockroomitem?assetNumber=${assetNumber}`);
    return new Item(data.result);
  }

  static async manageStockItem(info) {
    const api = await API();
    const response = await api.post("/stockroomitem", info);
    checkAPIResponse(response);
    return new Item(response.data.result);
  }
}
