
import { useEffect } from "react";
import {
  SubSectionTitle,
  SubTitle,
  defaultProductDevelopmentRevision,
} from "./constants";
import { SDSFormInput } from "./SDSFormInput";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import { removeMatchingProperties } from "../../../../global";

const ProductDevelopmentRevision = ({ commonProps, disabled }) => {
  const { data, setData } = commonProps;

  useEffect(() => {
    return () => {
      setData((prevData) => (removeMatchingProperties(prevData, defaultProductDevelopmentRevision)))
    };
  }, [setData])

  return (
    <div>
      <SubSectionTitle>SDS For Product Development - Revision</SubSectionTitle>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled}
          label="Provide Existing ProMIS Technical Spec"
          property="existingTechnicalSpec"
        />
      </div>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled}
          label="If known, please enter ECR #"
          property="ecrNumber"
        />
      </div>
      <SubTitle>Regions where product will be shipped and/or marketed:</SubTitle>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isNorthAmerica}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isNorthAmerica: e.target.checked
          }))}
          label={<>North America</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isSouthAmerica}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isSouthAmerica: e.target.checked
          }))}
          label={<>South America</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isEurope}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isEurope: e.target.checked
          }))}
          label={<>Europe</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isAfrica}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isAfrica: e.target.checked
          }))}
          label={<>Africa/Middle East</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isAsiaPacific}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isAsiaPacific: e.target.checked
          }))}
          label={<>Asia Pacific</>}
        />
      </FormGroup>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled}
          label="If country-specific SDSes are required, please specify"
          property="countrySpecificInfo"
        />
      </div>
    </div>
  );
};

export default ProductDevelopmentRevision;
