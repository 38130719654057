import API from "..";

export default class SDSRequestBusinessUnit {

  constructor({
    businessUnitName,
    isActive
  }) 
    
  {
    this.businessUnitName = businessUnitName;
    this.isActive = isActive;
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/sdsRequestBusinessUnit`);
    if (data.result) {
        return data.result.map((d) => new SDSRequestBusinessUnit(d));
    }
    else {
        return [];
    }
  }

  static async getAllActive() {
    const api = await API();
    const { data } = await api.get(`/sdsRequestBusinessUnit`);
    if (data && data.result && data.result.length >0) {
        return data.result.filter((d) => d.isActive);
    }
    else {
        return [];
    }
  }
}
