import API from "..";

export default class SDSRequestFileAttachment {
  
  constructor({
    id,
    sdsRequestID,
    sdsRequest,
    fileName,
    storagePath,
  }) 
    
  {
    this.id = id;
    this.sdsRequestID = sdsRequestID;
    this.sdsRequest = sdsRequest;
    this.fileName = fileName;
    this.storagePath = storagePath;
  }

  static async getAll(sdsRequestId) {
    const api = await API();
    const { data } = await api.get(`/sdsRequestFileAttachment/?sdsRequestId=${sdsRequestId}`);
    if (data.result) {
        return data.result.map((d) => new SDSRequestFileAttachment(d));
    }
    else {
        return [];
    }
  }

  static async postAttachs(info) {
    const api = await API('multipart/form-data');
    const { data } = await api.post('/sdsRequestFileAttachment', info);
    return new SDSRequestFileAttachment(data.result);
  }
}
