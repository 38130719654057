import React from "react";
import { Box, Divider } from "@mui/material";
import GeneralAdminMenu from "./General/GeneralAdminMenu";
import AnalyticalAdminMenu from "./AnalyticalAdminMenu";
import BlendingAdminMenu from "./BlendingAdminMenu";
import InventoryMasterDataManagement from "./InventoryMasterDataManagement";
import WorkRequestMasterData from "./WorkRequestMasterData";
import STMasterData from "./StationaryTesting/STMasterData";
import DistillationMasterData from "./DistillationMasterData";
import StockroomMasterData from "./StockroomMasterData";
import { GlobalTabCollection, StyledTab } from "../styles";

const Admin = ({ ...props }) => {
  const canAccessGeneral = true;
  const canAccessChemical = true;
  const canAccessAnalytical = true;
  const canAccessBlending = true;
  const canAccessWorkRequest = true;
  const canAccessST = true;
  const canAccessDistillation = true;
  const canAccessStockroom = true;

  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <GeneralAdminMenu />;
      }
      case 1: {
        return <InventoryMasterDataManagement />;
      }
      case 2: {
        return <AnalyticalAdminMenu />;
      }
      case 3: {
        return <BlendingAdminMenu />;
      }
      case 4: {
        return <WorkRequestMasterData />;
      }
      case 5: {
        return <STMasterData />;
      }
      case 6: {
        return <DistillationMasterData />;
      }
      case 7: {
        return <StockroomMasterData />;
      }
      default: {
        alert(tabValue);
      }
    }
  };

  return (
    <Box paddingBottom={4}>
      <span className="pageheader">Site Administration</span>
      <Divider className="dividerbar" />
      <div>
        <Box sx={{ bgcolor: "#fff", pt: 3, pb: 1 }}>
          <GlobalTabCollection value={tabValue} onChange={handleChange} aria-label="ant example">
            <StyledTab disabled={!canAccessGeneral} label="General Site Master Data" />
            <StyledTab
              disabled={!canAccessChemical}
              label="Chemical Inventory/Safety Master Data"
            />
            <StyledTab disabled={!canAccessAnalytical} label="Analytical Master Data" />
            <StyledTab disabled={!canAccessBlending} label="Blending Master Data" />
            <StyledTab disabled={!canAccessWorkRequest} label="Work Request Master Data" />
            <StyledTab disabled={!canAccessST} label="Stationary Testing Master Data" />
            <StyledTab disabled={!canAccessDistillation} label="Distillations Master Data" />
            <StyledTab disabled={!canAccessStockroom} label="Stockroom Master Data" />
          </GlobalTabCollection>
          <Box sx={{ p: 3 }} />
        </Box>
        {renderSearchTab()}
      </div>
    </Box>
  );
};

export default Admin;
