import React, { useState, useEffect } from "react";
import { Box, TextField, FormControl, Checkbox, Autocomplete, IconButton, CircularProgress } from "@mui/material";
import { styled } from "@mui/system";
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import BlendFacilityApi from "../../../../api/Formulations/BlendFacility";
import { GlobalButton } from "../../../styles";
import { StyledTableRow, StyledTableBody, StyledTableCell, UXDataTableWithoutBody } from "../../../../components/UXDataTable";
import PiscesBatch from "../../../../api/Reporting/PiscesBatch";
import { formatMidasNumber, exportToExcel, DatePickerKeyDownEvent, convertDateFormat } from "../../../../global";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import './styles.css';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const StyledFormControl = styled(FormControl)(() => ({
  display: 'flex',
  alignItems: 'start'
}));

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '20px'
}));

const StyledShortTextField = styled(TextField)({
  id: "outlined-normal",
  width: "20rem",
  marginRight: "15px"
});

const defaultSearchFields = {
  startDate: null,
  endDate: null,
  facility: null,
  facilities: []
}

const StyledAutocomplete = styled(Autocomplete)(() => ({
  width: "20rem",
  marginRight: "15px"
}));

const FormulationsMetrics = ({ ...props }) => {
  const [searchFields, setSearchFields] = useState(defaultSearchFields);
  const [blendFacilities, setBlendFacilities] = useState(null);
  const [listBlend, setListBlend] = useState([]);
  const [loadingBlend, setLoadingBlend] = useState(false);

  const isMountedRef = React.useRef(true);

  const colsBlend = ['Study Name', 'Blend Code', 'Batch #', 'Status',  'MIDAS #', 'Group', 'Priority', 'Requester', 'Submitted', 'Required', 'Prepared Date', 'Backlog Days', 'OnHold Days', 'Assigned Preparer', 'Project', 'Product Group', 'Requested Quantity', 'Unit', '# Components', 'JADE'];

  const fieldsBlend = [
    { key: 'blendStudyName' },
    { key: 'blendName' },
    { key: 'batchSequenceNumber' },
    { key: 'batchStatusName'},
    { key: 'preparedSampleName' },
    { key: 'blendFacilityName' },
    { key: 'priority' },
    { key: 'requester' },
    { key: 'submittedDate', type: 'dateTime' },
    { key: 'requestedCompletionDate', type: 'dateTime' },
    { key: 'preparedDate', type: 'dateTime' },
    { key: 'daysBacklogged' },
    { key: 'daysOnHold' },
    // { key: 'blendName' },
    { key: 'preparedByEmail' },
   // { key: 'description', width: '280px' },
    //{ key: 'blendPurpose' },
    { key: 'projectName' },
    { key: 'batchProductGroupName' },
    { key: 'requestedAmount' },
    { key: 'requestedAmountUoM' },
    { key: 'numberComponents' },
    { key: 'chargeCode' },
  ];

  function loadFacilityFilter() {
    BlendFacilityApi.getAllFacilities().then((result) => {
      if ((result !== undefined && result !== null)) {
        setBlendFacilities(result.sort((a, b) => a.blendFacilityName.localeCompare(b.blendFacilityName)));
      }
    });
  }

  useEffect(() => {
    loadFacilityFilter();

    isMountedRef.current = true;

    return () => {
      isMountedRef.current = false;
    }
  }, [])

  async function handleSearch() {
    setListBlend([])
    setLoadingBlend(true)
    getBlend(200, 1);
  }



  async function getBlend(resultsPerPage, page, allData = []) {
    if (isMountedRef.current) {
      const response = await PiscesBatch.get(
        searchFields.startDate.toISOString(),
        searchFields.endDate.toISOString(),
        searchFields.facilities,
        page,
        resultsPerPage)

      if (response) {
        allData = [...allData, ...response]
      } else {
        setListBlend([]);
      }

      if (response === null || response.length === 0) {
        setLoadingBlend(false)
        return allData;
      } else {
        page++;
        setListBlend(allData)
        return getBlend(resultsPerPage, page, allData);
      }
    }
  }

  const DownloadExcel = ({ list, cols, fields, name }) => {
    return <IconButton onClick={e => {
      e.preventDefault();
      exportToExcel(list.map(item => {
        const object = {};
        cols.forEach((col, index) => {
          object[col] = fields[index].type === 'dateTime' ? convertDateFormat(item[fields[index].key], false) : fields[index].key === 'preparedSampleName' ? formatMidasNumber(item[fields[index].key]) : item[fields[index].key]
          //  object[col] = fields[index].type === 'dateTime' ? convertToLocalTime(item[fields[index].key]) : fields[index].key === 'sampleName' ? formatMidasNumber(item[fields[index].key]) : item[fields[index].key]
        })
        return object;
      }), name)
    }}>
      <FileDownloadIcon sx={{ m: 1 }} style={{ cursor: "pointer" }} />
    </IconButton>
  }

  const Table = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const {colHeaders, loading, fields, list = [] } = props;

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    return (
      <div style={{marginTop: 30}}>
        <UXDataTableWithoutBody
          tableWidth='100%'
          cols={colHeaders}
          blankFirstHeader={false}
          tableId="simpleResultTable"
          rowLength={list.length}
          enablePaging={true}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          noDataFoundMessage={'There is no data available'}
          enableAddIcon={false}
          addFunction={null}
          addToolTipText={null}
          enableSorteable={false}
          isDataLoading={loading}
          noWrapCols={true}
        >
          <StyledTableBody key={"myTablebody"}>
            {list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, rowIndex) => {
              return (
                [
                  <StyledTableRow
                    hover
                    key={`custom-row-${rowIndex}`}>
                    {fields.map(field => {
                      return <StyledTableCell style={{ minWidth: field.width ?? '', whiteSpace: field.noWrap ? 'nowrap' : '' }}>{field.type === 'dateTime' ? convertDateFormat(item[field.key], false) : field.key === 'preparedSampleName' ? formatMidasNumber(item[field.key]) : item[field.key]}</StyledTableCell>
                    })}
                  </StyledTableRow>
                ]
              );
            })}
          </StyledTableBody>
        </UXDataTableWithoutBody>
      </div>
    )
  }

  return (
    <div style={{ paddingBottom: '20px' }}>
      <h4>Formulations Metrics</h4>
      <StyledFormControl fullWidth>
        <StyledBox>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              size="small"
              inputFormat="MM/dd/yyyy"
              label="Start Date"
              onChange={e => setSearchFields(() => ({
                ...searchFields,
                startDate: e
              }))}
              maxDate={searchFields.endDate}
              value={searchFields.startDate}
              renderInput={(params) => <StyledShortTextField onKeyDown={DatePickerKeyDownEvent} {...params} margin="normal" variant="outlined" size="small" InputLabelProps={{ shrink: true }} inputProps={{ ...params.inputProps }} InputProps={{ ...params.InputProps }} />}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              size="small"
              inputFormat="MM/dd/yyyy"
              label="End Date"
              onChange={e => setSearchFields(() => ({
                ...searchFields,
                endDate: e
              }))}
              minDate={searchFields.startDate}
              maxDate={new Date()}
              value={searchFields.endDate}
              renderInput={(params) => <StyledShortTextField onKeyDown={DatePickerKeyDownEvent} {...params} margin="normal" variant="outlined" size="small" InputLabelProps={{ shrink: true }} inputProps={{ ...params.inputProps }} InputProps={{ ...params.InputProps }} />}
            />
          </LocalizationProvider>
        </StyledBox>
        <StyledBox>
          <StyledAutocomplete
            size="small"
            id="facility-select"
            disablePortal
            noOptionsText={"Loading Facilities..."}
            options={blendFacilities !== null ?
              blendFacilities
                .map((facility) => facility.blendFacilityName.toString())
                .filter(f => !searchFields.facilities?.some(s => s === f)) : []}
            getOptionLabel={(option) => option}
            onChange={(e, value) => {
              value = value === null ? "" : value
              setSearchFields(() => ({
                ...searchFields,
                facility: value,
              }))
            }}
            autoHighlight
            autoSelect
            value={searchFields.facility === '' ? null : searchFields.facility}
            renderInput={(params) => <TextField {...params} margin="normal" variant="outlined" size="small" label="Facility" InputProps={{ ...params.InputProps }} />}
          />
          <GlobalButton
            style={{ marginTop: '8px', width: "12rem" }}
            variant='contained'
            onClick={() => {
              setSearchFields(() => ({
                ...searchFields,
                facilities: [...searchFields.facilities.filter(f => f !== searchFields.facility), searchFields.facility],
                facility: null
              }))
            }}
            disabled={!searchFields.facility}
          >Add Facility</GlobalButton>
          <StyledAutocomplete
            size="small"
            id="facilities-select"
            multiple
            limitTags={10}
            disableCloseOnSelect
            noOptionsText={"No Options"}
            options={searchFields.facilities}
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              setSearchFields(() => ({
                ...searchFields,
                facilities: newValue
              }))
            }}
            value={searchFields.facilities}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option}
              </li>
            )}
            style={{ width: 400 }}
            autoHighlight
            freeSolo
            renderInput={(params) => <TextField
              rows={1}
              multiline
              {...params}
              margin="normal"
              variant="outlined"
              size="small"
              label="Facilities to Search"
              inputProps={{ ...params.inputProps, readOnly: true }}
            />}
          />
          <GlobalButton
            style={{ marginTop: '8px', width: "12rem" }}
            variant='contained'
            onClick={handleSearch}
            disabled={(!searchFields.startDate || !searchFields.endDate) || loadingBlend}
          >Get Metrics</GlobalButton>
          {loadingBlend && <CircularProgress style={{textTransform: "none", marginLeft:"15px"}}></CircularProgress>}
          {listBlend.length > 0 && !loadingBlend && <DownloadExcel list={listBlend} cols={colsBlend} fields={fieldsBlend} name={'Blends'} />}
        </StyledBox>
      </StyledFormControl>
      <div>
        {<Table
          colHeaders={colsBlend}
          list={listBlend}
          loading={loadingBlend}
          fields={fieldsBlend}
        />}
      </div>
    </div>
  );
};

export default FormulationsMetrics; 