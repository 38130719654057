import { React, useState } from "react";
import { Box, Table, TableBody, TableCell, TableRow, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import { formatCreatedAndModifiedDateInfo } from "../../../global";
import { GlobalTabCollection, StyledTab } from "../../../pages/styles";

const fontSizeMinor = "13px"

const StyledTableCellMinor = styled(TableCell)({
    border: "none",
    paddingBottom:"0px",
    paddingTop:"0px",
    color:"#00000099",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: fontSizeMinor,
    width:"20%"
});

const StyledTableCellNoBorder = styled(TableCell)({
    border: "none",
    paddingBottom:"0px",
    paddingTop:"0px",
    color:"#000000DE",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: "16px",
    width:"20%"
});

const StyledTableHeaders = styled(TableCell)({
    paddingBottom:"0px",
    paddingTop:"0px",
    color:"#000000DE",
    fontFamily:"EMprint",
    fontWeight:"600",
    fontSize: fontSizeMinor,
});

const StyledTableCellMinor1 = styled(TableCell)({
    border: "none",
    paddingBottom:"0px",
    paddingTop:"0px",
    color:"#00000099",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: fontSizeMinor,
    width:"16.666%"
});

const StyledTableCellNoBorder1 = styled(TableCell)({
    border: "none",
    paddingBottom:"0px",
    paddingTop:"0px",
    color:"#000000DE",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: "16px",
    width:"16.666%"
});


const StyledTableCell = styled(TableCell)({
    border: "none",
    color:"#000000DE",
    fontFamily:"EMprint",
    fontWeight:"400",
    fontSize: fontSizeMinor
});

const StyledDivider = styled(Divider)({
    marginTop:"1rem"
});

const StyledP = styled('p')({
    color:"#000000DE",
    fontFamily:"EMprint",
    fontWeight:"600",
    fontSize: "16px",
    paddingTop:"2rem",
    paddingLeft:".6rem",
    paddingBottom:"2rem"
})

const StudySummary = ({ myStudy }) => {
    const [tabValue, setTabValue] = useState(0)
    const [tabValueBatch, setTabValueBatch] = useState(0)

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue)
     };

     const handleTabChangeBatch = (event, newValue) => {
        setTabValueBatch(newValue)
     };

  return (

    <div style={{paddingBottom:"2rem"}}>
        <div>
            <Box sx={{ boxShadow: 3,
            width: '95%',
            height: '19rem',
            p: 2,
            m: 1,
            borderRadius: 2,
            }}>
                <div style={{marginBottom:"1.5rem"}}> 
                    <p style={{
                        color:"#000000DE",
                        fontFamily:"EMprint",
                        fontWeight:"400",
                        fontSize: "20px",
                        paddingLeft:"1rem",
                    }}>{myStudy.studyName}</p>
                </div>

                    <Table>
                        <TableBody>
                            <TableRow>
                                <StyledTableCellMinor>Blend StudyID</StyledTableCellMinor>
                                <StyledTableCellMinor>Owner</StyledTableCellMinor>
                                <StyledTableCellMinor>Created Date</StyledTableCellMinor>
                                <StyledTableCellMinor>Created By</StyledTableCellMinor>
                                <StyledTableCellMinor>Project</StyledTableCellMinor>
                            </TableRow>
                            <TableRow>
                                <StyledTableCellNoBorder>{myStudy.id}</StyledTableCellNoBorder>
                                <StyledTableCellNoBorder>{myStudy.ownerEmail !== null ? myStudy.ownerEmail : '-'}</StyledTableCellNoBorder>
                                <StyledTableCellNoBorder>{myStudy.createdDate !== null ? myStudy.createdDate : '-'}</StyledTableCellNoBorder>
                                <StyledTableCellNoBorder>{myStudy.createdByEmail !== null  && myStudy.createdByEmail !== '' ? myStudy.createdByEmail : '-'}</StyledTableCellNoBorder>
                                <StyledTableCellNoBorder>{myStudy.project.name !== null ? myStudy.project.name : '-'}</StyledTableCellNoBorder>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Table style={{marginTop:"2rem"}}>
                        <TableBody>
                            <TableRow>
                                <StyledTableCellMinor>Keywords</StyledTableCellMinor>
                                <StyledTableCellMinor>Type</StyledTableCellMinor>
                                <StyledTableCellMinor>Last Modified Date</StyledTableCellMinor>
                                <StyledTableCellMinor>Last Modified By</StyledTableCellMinor>
                                <StyledTableCellMinor>Purpose</StyledTableCellMinor>
                            </TableRow>
                            <TableRow>
                                <StyledTableCellNoBorder>{myStudy.keywords !== null && myStudy.keywords !== '' ? myStudy.keywords : '-'}</StyledTableCellNoBorder>
                                <StyledTableCellNoBorder>{myStudy.blendStudyType.name !== null ? myStudy.blendStudyType.name : '-'}</StyledTableCellNoBorder>
                                <StyledTableCellNoBorder>{myStudy.lastModifiedDate !== null ? myStudy.lastModifiedDate : '-'}</StyledTableCellNoBorder>
                                <StyledTableCellNoBorder>{myStudy.lastModifiedByEmail !== null  && myStudy.lastModifiedByEmail !== '' ? myStudy.lastModifiedByEmail : '-'}</StyledTableCellNoBorder>
                                <StyledTableCellNoBorder>{myStudy.studyPurpose !== null && myStudy.studyPurpose !== '' ? myStudy.studyPurpose : '-'}</StyledTableCellNoBorder>
                            </TableRow>
                        </TableBody>
                    </Table>

                    <Table style={{marginTop:"2rem"}}>
                        <TableBody>
                            <TableRow>
                                <StyledTableCellMinor>Notes</StyledTableCellMinor>
                            </TableRow>
                            <TableRow>
                                <StyledTableCellNoBorder>{myStudy.notes !== null && myStudy.notes !== '' ? myStudy.notes : '-'}</StyledTableCellNoBorder>
                            </TableRow>
                        </TableBody>
                    </Table>
            </Box>
        </div>

        <div>
            <StyledP>Blends</StyledP>
            <GlobalTabCollection style={{width: myStudy.blendRequests.length > 10 ? "60%" :  `${myStudy.blendRequests.length * 6}%` }} value={tabValue} onChange={handleTabChange} variant={myStudy.blendRequests.length > 9 ? "scrollable" : "standard"} scrollButtons="auto" aria-label='ant example'>

            {/* <GlobalTabCollection style={{paddingLeft:".6rem", width: myStudy.blendRequests.length > 10 ? "60%" :  `${myStudy.blendRequests.length * 6}%` }} value={tabValue} onChange={handleTabChange} variant={"scrollable"} scrollButtons="auto" aria-label='ant example'> */}
                {myStudy.blendRequests.map((oBlend, index) => {
                    return(
                        <StyledTab key={`${oBlend.blendName}${index}`}
                        label={`Blend ${oBlend.blendName}`} />
                    )
                })}
            </GlobalTabCollection>

            <Box sx={{ boxShadow: 3,
                width: '95%',
                minHeight:'20rem',
                p: 3,
                m: 1,
                borderRadius: 2,
            }}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <StyledTableCellMinor1>Blend Name</StyledTableCellMinor1>
                            <StyledTableCellMinor1>Keywords</StyledTableCellMinor1>
                            <StyledTableCellMinor1>Comments</StyledTableCellMinor1>
                            {/* <StyledTableCellMinor1>Product Group</StyledTableCellMinor1>
                            <StyledTableCellMinor1>Purpose</StyledTableCellMinor1>
                            <StyledTableCellMinor1>Comments</StyledTableCellMinor1>
                            <StyledTableCellMinor1>Metals Free</StyledTableCellMinor1>
                            <StyledTableCellMinor1>ISO Blend</StyledTableCellMinor1> */}
                        </TableRow>
                        <TableRow>
                            <StyledTableCellNoBorder1>{`Blend ${myStudy.blendRequests[tabValue].blendName}`}</StyledTableCellNoBorder1>
                            <StyledTableCellNoBorder1>{`${myStudy.blendRequests[tabValue].keywords}`}</StyledTableCellNoBorder1>
                            <StyledTableCellNoBorder1>{`${myStudy.blendRequests[tabValue].blendComments}`}</StyledTableCellNoBorder1>
                            {/* <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].blendProductGroup.name}</StyledTableCellNoBorder1>
                            <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].blendPurpose !== null  && myStudy.blendRequests[tabValue].blendPurpose !== '' ? myStudy.blendRequests[tabValue].blendPurpose : '-'}</StyledTableCellNoBorder1>
                            <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].blendComments !== null  && myStudy.blendRequests[tabValue].blendComments !== '' ? myStudy.blendRequests[tabValue].blendComments : '-'}</StyledTableCellNoBorder1>
                            <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].isMetalsFree === true ? 'Yes' : 'No'}</StyledTableCellNoBorder1>
                            <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].isISOBlend === true ? 'Yes' : 'No'}</StyledTableCellNoBorder1> */}
                        </TableRow>
                    </TableBody>
                </Table>

                {/* <Table style={{marginTop:"2rem"}}>
                    <TableBody>
                    <TableRow>
                        <StyledTableCellMinor1>Charge Code</StyledTableCellMinor1>
                        <StyledTableCellMinor1>ProMIS R Code</StyledTableCellMinor1>
                        <StyledTableCellMinor1>CMA Oil Code</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Safety Information</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Keywords</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Reference</StyledTableCellMinor1>
                    </TableRow>
                     <TableRow>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].chargeCode !== null  && myStudy.blendRequests[tabValue].chargeCode !== ''?  myStudy.blendRequests[tabValue].chargeCode : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].proMISRCode !== null  && myStudy.blendRequests[tabValue].proMISRCode !== '' ? myStudy.blendRequests[tabValue].proMISRCode : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].cmaOilCode !== null  && myStudy.blendRequests[tabValue].cmaOilCode !== '' ? myStudy.blendRequests[tabValue].cmaOilCode : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].warning !== null  && myStudy.blendRequests[tabValue].warning !== '' ? myStudy.blendRequests[tabValue].warning : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].keywords !== null  && myStudy.blendRequests[tabValue].keywords !== '' ? myStudy.blendRequests[tabValue].keywords : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].reference !== null  && myStudy.blendRequests[tabValue].reference !== '' ? myStudy.blendRequests[tabValue].reference : '-'}</StyledTableCellNoBorder1>
                    </TableRow>
                    </TableBody>
                </Table> */}

                <div style={{paddingTop: "0rem", marginLeft: "1rem"}}>
                    {formatCreatedAndModifiedDateInfo(myStudy.createdDate === null ? 'N/A' : myStudy.createdDate, 
                        myStudy.createdByEmail === null ||  myStudy.createdByEmail.trim() === '' ? 'N/A' : myStudy.createdByEmail,
                        myStudy.lastModifiedDate === null ? 'N/A' : myStudy.lastModifiedDate, 
                        myStudy.lastModifiedByEmail === null || myStudy.lastModifiedByEmail.trim() === '' ? 'N/A' : myStudy.lastModifiedByEmail)}
                </div>

                <StyledDivider></StyledDivider>

                <StyledP>Blend Components</StyledP>

                <Table>
                    <TableBody>
                        <TableRow>
                            <StyledTableHeaders>Chem ID</StyledTableHeaders>
                            <StyledTableHeaders>Description</StyledTableHeaders>
                            <StyledTableHeaders>MIDAS Number</StyledTableHeaders>
                            <StyledTableHeaders>Alt. MIDAS Number</StyledTableHeaders>
                            <StyledTableHeaders>Target Amount</StyledTableHeaders>
                            <StyledTableHeaders>Target UoM</StyledTableHeaders>
                            <StyledTableHeaders>Addition Order</StyledTableHeaders>
                            <StyledTableHeaders>Addition Temp (C)</StyledTableHeaders>
                        </TableRow>
                            {myStudy.blendRequests[tabValue].blendComponents.map((oComponent, index) => {
                                return(
                                    <TableRow key={`blendComponentRow${index}-${tabValue}`}>
                                        <StyledTableCell>{oComponent.substance?.chemID}</StyledTableCell>
                                        <StyledTableCell>{oComponent.substance?.substanceName}</StyledTableCell>
                                        <StyledTableCell>{oComponent.preferredSample !== null ? oComponent.preferredSample.sampleName : '-'}</StyledTableCell>
                                        <StyledTableCell>{oComponent.secondaryPreferredSample !== null ? oComponent.secondaryPreferredSample.sampleName : '-'}</StyledTableCell>
                                        <StyledTableCell>{oComponent.targetAmountLower !== null ? oComponent.targetAmountLower : '-'}</StyledTableCell>
                                        <StyledTableCell>{oComponent.componentUoM !== null ? oComponent.componentUoM.uoMName : '-'}</StyledTableCell>
                                        <StyledTableCell>{oComponent.addOrder !== null ? oComponent.addOrder : '-'}</StyledTableCell>
                                        <StyledTableCell>{oComponent.blendAdditionTemperatureC !== null ? oComponent.blendAdditionTemperatureC : '-'}</StyledTableCell>
                                    </TableRow>
                                )
                            })}
                    </TableBody>
                </Table>
            </Box>

            <StyledP>Batches</StyledP>
            <GlobalTabCollection style={{width: myStudy.blendRequests[tabValue].batches.length > 10 ? "80%" :  `${myStudy.blendRequests[tabValue].batches.length * 8}%` }} value={tabValueBatch} onChange={handleTabChangeBatch} variant={myStudy.blendRequests[tabValue].batches.length > 9 ? "scrollable" : "standard"} scrollButtons="auto" aria-label='ant example'>
            {/* <GlobalTabCollection style={{paddingLeft:".6rem", width: myStudy.blendRequests[tabValue].batches.length > 10 ? "60%" :  `${myStudy.blendRequests[tabValue].batches.length * 6}%` }} value={tabValueBatch} onChange={handleTabChangeBatch} variant={"scrollable"} scrollButtons="auto" aria-label='ant example'> */}
                {myStudy.blendRequests[tabValue].batches.map((oBatch, index) => {
                    return(
                        <StyledTab key={`${oBatch.batchSequenceNumber}${index}`}
                        label={`Batch ${oBatch.batchSequenceNumber}`} />
                    )
                })}
            </GlobalTabCollection>

            <Box sx={{ boxShadow: 3,
                width: '95%',
                minHeight:'20rem',
                p: 3,
                m: 1,
                borderRadius: 2,
            }}>

            <Table>
                <TableBody>
                    <TableRow>
                        <StyledTableCellMinor1>Status</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Target Amount</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Precision</StyledTableCellMinor1>
                        <StyledTableCellMinor1>UoM</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Requested Completion</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Retain Location</StyledTableCellMinor1>
                    </TableRow>
                    <TableRow key={`batchesRow${tabValueBatch}-${tabValue}_1`}>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatus !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatus !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].batchStatus.batchStatusName : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedAmount !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedAmount !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedAmount : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].batchPrecision !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchPrecision !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].batchPrecision : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested !== null ? myStudy.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested.uoMName : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedCompletionDate !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedCompletionDate !== '' ? new Date(myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedCompletionDate).toDateString().split(' ').slice(1).join(' '): '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].batchRetainLocation !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchRetainLocation !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].batchRetainLocation.locationName : '-'}</StyledTableCellNoBorder1>

{/*                        
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested !== null ? myStudy.blendRequests[tabValue].batches[tabValueBatch].unitOfMeasureRequested.uoMName : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedCompletionDate !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedCompletionDate !== '' ? new Date(myStudy.blendRequests[tabValue].batches[tabValueBatch].requestedCompletionDate).toDateString().split(' ').slice(1).join(' '): '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].blendPriority !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].blendPriority !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].blendPriority.blendPriorityName : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].priorityReason !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].priorityReason !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].priorityReason : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].destination !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].destination !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].destination : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].batchRetainLocation !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchRetainLocation !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].batchRetainLocation.locationName : '-'}</StyledTableCellNoBorder1> */}
                    </TableRow>
                </TableBody>
            </Table>

            <Table style={{marginTop:"1rem"}}>
                <TableBody>
                    <TableRow>
                        <StyledTableCellMinor1>Cost Code</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Destination</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Blend Facility</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Product Group</StyledTableCellMinor1>
                        <StyledTableCellMinor1>CMA Oil Code</StyledTableCellMinor1>
                        <StyledTableCellMinor1>ProMIS R Code</StyledTableCellMinor1>
                    </TableRow>
                    <TableRow key={`batchesRow${tabValueBatch}-${tabValue}_2`}>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].chargeCode !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].chargeCode !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].chargeCode : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].destination !== null&& myStudy.blendRequests[tabValue].batches[tabValueBatch].destination !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].destination : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].blendFacility !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].blendFacility !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].blendFacility.blendFacilityName : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].batchProductGroup !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchProductGroup !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].batchProductGroup.name : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].cmaOilCode !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].cmaOilCode !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].cmaOilCode : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].proMISRCode !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].proMISRCode !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].proMISRCode : '-'}</StyledTableCellNoBorder1>
                    </TableRow>
                </TableBody>
            </Table>

            <Table style={{marginTop:"1rem"}}>
                <TableBody>
                    <TableRow>
                        <StyledTableCellMinor1>Reference</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Priority</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Priority Reason</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Safety Info</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Description</StyledTableCellMinor1>
                        <StyledTableCellMinor1>Purpose</StyledTableCellMinor1>
                    </TableRow>
                    <TableRow key={`batchesRow${tabValueBatch}-${tabValue}_3`}>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].reference !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].reference !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].reference : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].priority !== null&& myStudy.blendRequests[tabValue].batches[tabValueBatch].priority !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].priority : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].priorityReason !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].priorityReason !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].priorityReason : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].safetyInformation !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].safetyInformation !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].safetyInformation : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].description !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].description !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].description : '-'}</StyledTableCellNoBorder1>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].batchPurpose !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].batchPurpose !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].batchPurpose : '-'}</StyledTableCellNoBorder1>
                    </TableRow>
                </TableBody>
            </Table>

            
            <Table style={{marginTop:"1rem"}}>
                <TableBody>
                    <TableRow>
                        <StyledTableCellMinor1>MIDAS #</StyledTableCellMinor1>
                    </TableRow>
                    <TableRow key={`batchesRow${tabValueBatch}-${tabValue}_4`}>
                        <StyledTableCellNoBorder1>{myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedSampleName !== null && myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedSampleName !== '' ? myStudy.blendRequests[tabValue].batches[tabValueBatch].preparedSampleName : '-'}</StyledTableCellNoBorder1>
                    </TableRow>
                </TableBody>
            </Table>

                <StyledDivider></StyledDivider>

                <StyledP>Tests</StyledP>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <StyledTableHeaders>Method</StyledTableHeaders>
                                <StyledTableHeaders>Description</StyledTableHeaders>
                                <StyledTableHeaders>Facility</StyledTableHeaders>
                                <StyledTableHeaders>Offering</StyledTableHeaders>
                                <StyledTableHeaders>Estimate</StyledTableHeaders>
                                <StyledTableHeaders>Priority</StyledTableHeaders>
                            </TableRow>
                                {myStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingObject && myStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingObject !== '' && myStudy.blendRequests[tabValue].batches[tabValueBatch].analyticalTestingObject.map((oTestObj, index) => {
                                    return(
                                        <TableRow key={`testingRow${index}-${tabValue}`}>
                                            <StyledTableCell>{oTestObj.method !== null && oTestObj.method.name !== '' ? oTestObj.method.name : '-'}</StyledTableCell>
                                            <StyledTableCell>{oTestObj.method !== null && oTestObj.method.description !== '' ? oTestObj.method.description : '-'}</StyledTableCell>
                                            <StyledTableCell>{oTestObj.methodFacility !== null  && oTestObj.methodFacility.testFacilityAbv !== '' ? oTestObj.methodFacility.testFacilityAbv : '-'}</StyledTableCell>
                                            <StyledTableCell>
                                                {oTestObj.conditionOffering !== null ? oTestObj.conditionOffering.conditionOfferingName : '-'}
                                            
                                            </StyledTableCell>
                                            <StyledTableCell>{oTestObj.estimate !== null && oTestObj.estimate !== '' ? oTestObj.estimate : '-'}</StyledTableCell>
                                            <StyledTableCell>{oTestObj.testPriority !== null && oTestObj.testPriority.testPriorityName !== '' ? oTestObj.testPriority.testPriorityName : '-'}</StyledTableCell>
                                        </TableRow>
                                    )
                                })}
                        </TableBody>
                    </Table>

            </Box>
        </div>
    </div>
  );
};

export default StudySummary;