import API from "..";

export default class SDSRequestPriority {

  constructor({
    priorityName,
    isActive,
    priorityLevel
  }) 
    
  {
    this.priorityName = priorityName;
    this.isActive = isActive;
    this.priorityLevel = priorityLevel
  }

  static async getAll() {
    const api = await API();
    const { data } = await api.get(`/sdsRequestPriority`);
    if (data.result) {
        return data.result.map((d) => new SDSRequestPriority(d));
    }
    else {
        return [];
    }
  }
}
