import { Box, CircularProgress } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import SDSRequestApi from "../../../../api/SDSRequest/SDSRequest";
import SDSRequestBusinessUnit from "../../../../api/SDSRequest/SDSRequestBusinessUnit";
import SDSRequestPriority from "../../../../api/SDSRequest/SDSRequestPriority";
import SDSRequestType from "../../../../api/SDSRequest/SDSRequestType";
import SDSRequestFileAttachment from "../../../../api/SDSRequest/SDSRequestFileAttachment";
import SDSRequestIntendedUse from "../../../../api/SDSRequest/SDSRequestIntendedUse";
import SDSRequestSyntheticIndicator from "../../../../api/SDSRequest/SDSRequestSyntheticIndicator";
import Project from "../../../../api/Admin/Project";
import EmailAddressTextField from "../../../../components/EmailAddressField";
import FileDragAndDrop from "../../../../components/FileDragAndDrop";
import UserContext from "../../../../context/UserContext";
import { GlobalButton, GlobalSecondaryButton } from "../../../styles";
import {
  StyledTextField,
  StyledDiv,
  StyledDivider,
  SubTitle,
  defaultSDSRequest,
  SubSectionTitle,
  defaultErrors,
  labelProp,
} from "./constants";
import { SDSFormAutocomplete, SDSFormDate, SDSFormInput } from "./SDSFormInput";
import ModalSimpleButton from "../../../../components/Modal/ModalSimpleButton";
import ProductDevelopmentRelease from "./ProductDevelopmentRelease";
import ProductDevelopmentRevision from "./ProductDevelopmentRevision";
import Shipment from "./Shipment";
import BlindCoding from "./BlindCoding";
import SDSRequestStatus from "../../../../api/SDSRequest/SDSRequestStatus";
import toast from "react-hot-toast";

const SubmitSDS = ({ ...props }) => {

  const { request } = props;
  const currentUser = useContext(UserContext);
  const [mySDSRequest, setMySDSRequest] = useState({ ...defaultSDSRequest });
  const [businessUnits, setBusinessUnits] = useState([]);
  const [businessUnitsLoading, setBusinessUnitsLoading] = useState(true);
  const [intendedUses, setIntendedUses] = useState([]);
  const [intendedUsesLoading, setIntendedUsesLoading] = useState(true);
  const [syntheticIndicators, setSyntheticIndicators] = useState([]);
  const [syntheticIndicatorsLoading, setSyntheticIndicatorsLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [sdsRequestPriority, setSDSRequestPriority] = useState("");
  const [newComment, setNewComment] = useState("");
  const [sdsRequestPriorityLoading, setSDSRequestPriorityLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errors, setErrors] = useState(defaultErrors);
  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const [modalMessagesText, setModalMessagesText] = useState("");
  const [modalMessagesTitle, setModalMessagesTitle] = useState("");
  const [modalMessagesButtonText, setModalMessagesButtonText] = useState("Ok");
  const [sdsRequestType, setSDSRequestType] = useState([]);
  const [sdsRequestTypeLoading, setSDSRequestTypeLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [sdsRequestStatus, setSDSRequestStatus] = useState([]);
  const [sdsRequestStatusLoading, setSDSRequestStatusLoading] = useState(true);
  const [originalRequestStatus, setOriginalRequestStatus] = useState(null);
  const [requiredCommentAdded, setRequiredCommentAdded] = useState(false);

  const hasError = () => {
    return !request
      ? Object.entries(errors).some(([key, value]) => value === null || value === true)
      : null;
  }

  function updateErrors() {
    setErrors((prevErrors) => {
      const updatedErrors = Object.keys(prevErrors).reduce((acc, key) => {
        acc[key] = prevErrors[key] === null ? true : prevErrors[key];
        return acc;
      }, {});

      return updatedErrors;
    });
  }

  const errorsToString = Object.entries(errors).filter(([key, value]) => value === null || value === true).map(([key]) => labelProp[key]).join('\n')

  const commonProps = {
    setData: setMySDSRequest,
    data: mySDSRequest,
    setErrors,
    errors
  }

  const mountComments = (comments) => {
    let comment = "";
    comments.forEach((item) => {
      comment += item + "\n";
    });
    return comment;
  };

  useEffect(() => {
    let cancelPromise = false;

    Promise.all([
      SDSRequestType.getAll()
        .then((res) => {
          setSDSRequestType(res.map(type => ({
            ...type,
            requestTypeName: type.typeName
          })));
          setSDSRequestTypeLoading(false);
        })
        .catch(() => {
          setSDSRequestTypeLoading(false);
        }),
      SDSRequestPriority.getAll()
        .then((res) => {
          setSDSRequestPriority(res.map(priority => ({
            ...priority,
            priority: priority.priorityName
          })));
          setSDSRequestPriorityLoading(false);
        })
        .catch(() => {
          setSDSRequestPriorityLoading(false);
        }),
      SDSRequestBusinessUnit.getAllActive().then((res) => {
        if (cancelPromise) return;
        setBusinessUnitsLoading(false);
        setBusinessUnits(
          res.sort((a, b) =>
            a.businessUnitName.localeCompare(b.businessUnitName)
          )
        );
      }),
      SDSRequestIntendedUse.getAllActive().then((res) => {
        if (cancelPromise) return;
        setIntendedUsesLoading(false);
        setIntendedUses(
          res.sort((a, b) =>
            a.intendedUseName.localeCompare(b.intendedUseName)
          )
        );
      }),
      SDSRequestSyntheticIndicator.getAllActive().then((res) => {
        if (cancelPromise) return;
        setSyntheticIndicatorsLoading(false);
        setSyntheticIndicators(
          res.sort((a, b) =>
            a.syntheticIndicatorName.localeCompare(b.syntheticIndicatorName)
          )
        );
      }),
      Project.getAll().then((res) => {
        if (cancelPromise) return;
        setProjectsLoading(false);
        setProjects(
          res.map((project) => ({
            ...project,
            projectID: project.id
          })).sort((a, b) =>
            a.name.localeCompare(b.name)
          )
        );
      }),
      SDSRequestStatus.getAll()
        .then((res) => {
          if (cancelPromise) return;
          setSDSRequestStatus(res.map(status => ({
            ...status,
            requestStatusName: status.statusName
          })));
          setSDSRequestStatusLoading(false);
        })
        .catch(() => {
          setSDSRequestStatusLoading(false);
        })
    ]).then(() => { console.log('initial load ran') });

    return () => {
      cancelPromise = true;
    };
  }, []);

  useEffect(() => {
    if (request) {
      setMySDSRequest(request);
      setOriginalRequestStatus(request.requestStatusName);
      SDSRequestFileAttachment.getAll(request.id)
        .then((res) => {
          setFiles(res.map(f => ({
            file: {
              ...f,
              fileName: f.fileName,
              storagePath: f.storagePath,
              canDelete: false
            }
          })));
        })
        .catch(() => {
          toast.error("Could not load the request attachments");
        });
    }
  }, [request]);

  function handleFiles(newFiles) {
    const listFiles = newFiles.map((item) => {
      return {
        file: item,
      };
    });
    setFiles(files.concat(listFiles));
  }

  function deleteFile(file) {
    const newFiles = files.filter((f) => f.file !== file);
    setFiles(newFiles);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
    setModalMessagesButtonText("Ok");
  }

  function closeModalMessages() {
    setModalMessagesOpen(false);
    setModalMessagesText("");
    setModalMessagesButtonText("");
    setModalMessagesTitle("");
  }

  const prepareData = async (sdsRequest) => {
    if (request) {
      if (sdsRequest.requestStatusName === 'Submitted' && sdsRequest.assignedToEmail)
        sdsRequest.requestStatusName = 'Assigned';
    }

    return {
      ...sdsRequest,
      project: null,
      businessUnit: null,
      sdsRequestType: null,
      sdsRequestStatus: null,
      sdsRequestPriority: null,
    };
  };

  async function sendFiles(id) {
    if (files.length > 0) {
      let result = await Promise.all(
        files.map(async (fileObj, index) => {
          if (fileObj.file instanceof File) {
            const formData = new FormData();
            formData.append("file", fileObj.file);
            formData.append("sdsRequestID", id);
            let resp = await SDSRequestFileAttachment.postAttachs(formData);
            return resp;
          }
        })
      );
      if (!!result.length) return true;
      else return false;
    }
    return true;
  }

  function finishedSubmit() {
    cancel();
    if (!request)
      openModalMessages("SDS Request Create", "SDS Request Created Successfully");
    else
      toast.success("SDS Request Updated Successfully");
  }

  function validateRequiredComment() {
    return (
      (originalRequestStatus === "Cancelled" || originalRequestStatus === "Completed") &&
      (mySDSRequest.requestStatusName !== originalRequestStatus &&
        ((mySDSRequest.requestStatusName !== "Cancelled" || mySDSRequest.requestStatusName !== "Completed"))
      ) && !requiredCommentAdded);
  }

  function cancel() {
    setModalMessagesButtonText("Ok");
    setMySDSRequest(defaultSDSRequest);
    setOriginalRequestStatus(null);
    setRequiredCommentAdded(false);
    setErrors(defaultErrors);
    setFiles([]);
    setNewComment("");
    if (props.reloadList)
      props.reloadList();
  }

  async function submit() {
    if (hasError()) {
      updateErrors();
      openModalMessages(
        "Missing information!",
        "There are required fields missing:\n\n" + errorsToString
      );
      return;
    }

    if (validateRequiredComment()) {
      openModalMessages("Missing information!", "You have to add a new comment when re-opening a request.");
      return;
    }

    setLoadingSubmit(true);

    const data = await prepareData(mySDSRequest);
    if (!request)
      SDSRequestApi.createSDSRequest(data)
        .then(async (res) => {
          if (res?.message !== 'Success') {
            openModalMessages('Fail', `Something went wrong creating the SDS Requests, please try again later. ${res?.message ? res?.message : ""}`, 'Ok');
            setLoadingSubmit(false);
          } else {
            const sdsRequest = res.result;
            let filesSent = await sendFiles(sdsRequest.id);

            if (filesSent) finishedSubmit(true);

            setLoadingSubmit(false);
          }
        })
        .catch((err) => {
          setLoadingSubmit(false);
          openModalMessages('Fail', `Something went wrong creating the SDS Requests, please try again later. ${err?.message ? err?.message : ""}`, 'Ok');
        });
    else
      SDSRequestApi.updateSDSRequest(data)
        .then(async (res) => {
          if (res?.message !== 'Success') {
            openModalMessages('Fail', `Something went wrong while updating the SDS Request, please try again later. ${res?.message ? res?.message : ""}`, 'Ok');
            setLoadingSubmit(false);
          } else {
            const sdsRequest = res.result;
            let filesSent = await sendFiles(sdsRequest.id);
            if (filesSent) finishedSubmit(true);
            setLoadingSubmit(false);
          }
        })
        .catch((err) => {
          setLoadingSubmit(false);
          openModalMessages('Fail', `Something went wrong creating the SDS Requests, please try again later. ${err?.message ? err?.message : ""}`, 'Ok');
        });
  }

  function isRequestFinishedStatus() {
    return mySDSRequest.requestStatusName === "Cancelled" || mySDSRequest.requestStatusName === "Completed";
  }

  const renderInputsByType = () => {
    switch (mySDSRequest.requestTypeName) {
      case 'ProductDevelopmentRelease':
        return <ProductDevelopmentRelease commonProps={commonProps} disabled={request ? true : false} syntheticIndicators={syntheticIndicators} syntheticIndicatorsLoading={syntheticIndicatorsLoading} />;
      case 'ProductDevelopmentRevision':
        return <ProductDevelopmentRevision commonProps={commonProps} disabled={request ? true : false} />;
      case 'BlindCoding':
        return <BlindCoding commonProps={commonProps} disabled={request ? true : false} />;
      case 'Shipment':
        return <Shipment commonProps={commonProps} disabled={request ? true : false} />;
      default:
        return <></>;
    }
  }

  return (
    <div>
      {request &&
        <Box style={{ width: "100%" }}>
          <div style={{ display: "grid", gap: "1rem", height: 60, gridTemplateColumns: "1fr 3fr 4fr" }}>

            <SDSFormInput
              {...commonProps}
              disabled
              label={labelProp['id']}
              property="id"
            />

            <SDSFormAutocomplete
              {...commonProps}
              setData={(data) => {
                commonProps.setData(data);
              }}
              options={sdsRequestStatus}
              property={'requestStatusName'}
              noOptionsText={
                sdsRequestStatusLoading
                  ? "Loading Statuses..."
                  : "No Status Found"
              }
              label={labelProp['requestStatusName']}
            />

            <div style={{ marginBottom: "20px" }}>
              <EmailAddressTextField
                fontSize={16}
                validatedUserEmail={!mySDSRequest.assignedToEmail ? "" : mySDSRequest.assignedToEmail}
                setValidatedUserEmail={(value) => {
                  setMySDSRequest(() => ({ ...mySDSRequest, assignedToEmail: value }));
                }}
                setHasErrors={(error) => {
                  setErrors((prev) => ({ ...prev, assignedToEmail: error }));
                }}
                hasErrors={errors.assignedToEmail === null ? false : errors.assignedToEmail}
                labelText={labelProp['assignedToEmail']}
                showPlusMeButton={true}
              />
            </div>

          </div>
        </Box>
      }

      <Box style={{ width: "100%" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 2fr 1fr 1fr 1fr 1fr",
            gap: "1rem",
            height: 60
          }}
        >
          <SDSFormAutocomplete
            {...commonProps}
            disabled={request ? true : false}
            options={projects}
            property={'projectID'}
            propertyLabel={'name'}
            noOptionsText={
              projectsLoading ? "Loading Projects..." : "No Projects Found"
            }
            label={labelProp['projectID']}
            required
          />
          <SDSFormAutocomplete
            {...commonProps}
            disabled={request ? true : false}
            options={sdsRequestType}
            property={'requestTypeName'}
            propertyLabel={'description'}
            noOptionsText={
              sdsRequestTypeLoading ? "Loading Types..." : "No Types Found"
            }
            label={labelProp['requestTypeName']}
            required
          />
          <SDSFormAutocomplete
            {...commonProps}
            disabled={request ? true : false}
            options={businessUnits}
            property={'businessUnitName'}
            noOptionsText={
              businessUnitsLoading
                ? "Loading Business Units..."
                : "No Business Units Found"
            }
            label={labelProp['businessUnitName']}
            required
          />
          <SDSFormAutocomplete
            {...commonProps}
            disabled={request ? true : false}
            options={intendedUses}
            styled={{ marginBottom: 0 }}
            noOptionsText={
              intendedUsesLoading
                ? "Loading Intended Use..."
                : "No Intended Use Found"
            }
            property={'intendedUseName'}
            label={labelProp['intendedUseName']}
            required
          />

          <SDSFormDate
            {...commonProps}
            disabled={request ? true : false}
            property={'requestedCompletionDate'}
            label={labelProp['requestedCompletionDate']}
            minDate={new Date()}
            maxDate={
              mySDSRequest.lastAcceptableCompletionDate
                ? new Date(mySDSRequest.lastAcceptableCompletionDate)
                : null
            }
            required
          />

          <SDSFormDate
            {...commonProps}
            disabled={request ? true : false}
            property={'lastAcceptableCompletionDate'}
            label={labelProp['lastAcceptableCompletionDate']}
            minDate={
              new Date(mySDSRequest.requestedCompletionDate || new Date())
            }
            required
          />
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 4fr",
            gap: "1rem",
            height: 60
          }}
        >
          <SDSFormAutocomplete
            {...commonProps}
            disabled={request ? true : false}
            setData={(data) => {
              if (data?.priority === 'Rush') {
                setErrors({
                  ...errors,
                  priorityReason: null
                })
              } else {
                delete errors.priorityReason;
              }
              commonProps.setData(data);
            }}
            options={sdsRequestPriority}
            property={'priority'}
            noOptionsText={
              sdsRequestPriorityLoading
                ? "Loading Priorities..."
                : "No Priorities Found"
            }
            label='Priority'
          />

          <SDSFormInput
            {...commonProps}
            disabled={request ? true : false}
            label={labelProp['priorityReason']}
            property="priorityReason"
            required={'priorityReason' in errors}
          />
        </div>

        <div style={{ display: "flex", gap: "10px", alignItems: "baseline" }}>
          <SDSFormInput
            {...commonProps}
            disabled={request ? true : false}
            label={labelProp['description']}
            property="description"
            required
          />

          <EmailAddressTextField
            fontSize={16}
            margin={"none"}
            fieldWidth={"30%"}
            validatedUserEmail={
              mySDSRequest.requesterByEmail === null
                ? ""
                : mySDSRequest.requesterByEmail
            }
            setValidatedUserEmail={(value) => {
              setMySDSRequest(() => ({
                ...mySDSRequest,
                requesterByEmail: value,
              }));
            }}
            setHasErrors={(error) => {
              setErrors((prev) => ({
                ...prev,
                requesterByEmail: error,
              }));
            }}
            hasErrors={
              errors.requesterByEmail === null
                ? false
                : errors.requesterByEmail
            }
            isDisabled={request ? true : false}
            labelText={`* ${labelProp['requesterByEmail']}`}
            placeholderText="* Requester Email"
            showPlusMeButton={true}
          />
        </div>
      </Box>
      <SubTitle>Add Comment</SubTitle>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <StyledTextField
          size="small"
          margin="normal"
          variant="outlined"
          label="Comment"
          InputLabelProps={{ shrink: true }}
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          disabled={!!request && isRequestFinishedStatus()}
        />

        <GlobalButton
          style={{ width: 160 }}
          name="saveComment"
          variant="contained"
          onClick={() => {
            let timeStamp = new Date().toLocaleString();

            let commentWithDate = `${currentUser.username} (${timeStamp}):  ${newComment}`;
            let comment = mySDSRequest.comments
              ? commentWithDate + "|" + mySDSRequest.comments
              : commentWithDate;
            setMySDSRequest(() => ({
              ...mySDSRequest,
              comments: comment,
            }));
            setNewComment("");
            setRequiredCommentAdded(true);
          }}
          disabled={newComment === "" || !newComment || (!!request && isRequestFinishedStatus())}
        >
          Save Comment
        </GlobalButton>
      </div>
      <div style={{ display: "flex" }}>
        <StyledTextField
          size="small"
          margin="normal"
          variant="outlined"
          label="Comments History"
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 500 }}
          multiline
          rows={4}
          value={
            mySDSRequest.comments
              ? mountComments(mySDSRequest.comments.split("|"))
              : ""
          }
          disabled={true}
        />
      </div>

      <StyledDivider />

      <StyledDiv>
        <SubSectionTitle>File Attachments</SubSectionTitle>
      </StyledDiv>

      <FileDragAndDrop
        container={`sdsrequestfiles`}
        handleFiles={handleFiles}
        files={files.map((item) => item.file)}
        showFiles={true}
        handleDeleteFile={(file) => deleteFile(file)}
        message={"Drag and drop your SDS file here"}
        disabled={!!request && isRequestFinishedStatus()}
      />
      <a href="https://ishareteam7.na.xom.com/sites/Midas3Training/Training%20Documents/SDS%20Request%20Template.xlsx">SDS Request Template Product/Formulation Information</a>
      {renderInputsByType()}

      <Box display="flex" paddingTop={2} paddingBottom={4} gap={2}>
        <GlobalButton name="btnSave" variant="contained" onClick={submit} disabled={loadingSubmit ? true : false}>
          Submit
        </GlobalButton>
        {loadingSubmit && <CircularProgress style={{ textTransform: "none", marginLeft: "15px" }}></CircularProgress>}
        {request && <GlobalSecondaryButton name="btnCancel" variant="contained" onClick={cancel}>Cancel</GlobalSecondaryButton>}
      </Box>

      <ModalSimpleButton
        title={modalMessagesTitle}
        buttonText={modalMessagesButtonText}
        buttonAction={closeModalMessages}
        open={modalMessagesOpen}
        setOpen={setModalMessagesOpen}
      >
        <label style={{ whiteSpace: "break-spaces" }}>
          {modalMessagesText}
        </label>
      </ModalSimpleButton>
    </div>
  );
};

export default SubmitSDS;
