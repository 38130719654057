import { CircularProgress, Grid, TableRow, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { newContainerErrorChecks } from "../../NewWorkRequests/constants";
import Location from "../../../../api/Admin/Location";
import UnitOfMeasure from "../../../../api/Admin/UnitOfMeasure";
import ContainerStatus from "../../../../api/LIMS/ContainerStatus";
import ContainerType from "../../../../api/LIMS/ContainerType";
import WorkRequest from "../../../../api/WorkRequest/WorkRequest";
import ModalMessages from "../../../../components/Modal/ModalSimpleButton";
import LoadingButton from "@mui/lab/LoadingButton";
import Container from "../../../../api/LIMS/Container";
import EmailAddressTextField from "../../../../components/EmailAddressField";
import {
  StyledTableBody,
  StyledTableCell,
  UXDataTableWithoutBody,
} from "../../../../components/UXDataTable";
import { GlobalButton, Option, StyledAutocomplete } from "../../../styles";
import {ConvertUOMs, RecalculateCurrentAmountOnUoMChange, RecalculateInitialSizeOnUoMChange, GetRequiredSampleSize, GetRequiredSampleSizeUoM, BuildTestingPayload} from "../../../../global";

const containerGridFontSize = 12;
const newContainersCol = [
  "Midas #",
  "Container #",
  "Parent Container",
  "Type",
  "Status",
  "Initial Size",
  "Current Amt",
  "UoM",
  "% Full",
  "Location",
  "Sublocation",
  "Owner",
];
const newContainersColWithTest = [
  "Midas #",
  "Tests",
  "Container #",
  "Parent Container",
  "Type",
  "Status",
  "Initial Size",
  "Current Amt",
  "UoM",
  "% Full",
  "Location",
  "Sublocation",
  "Owner",
];

export default function ManageContainer({
  workRequest,
  setWorkRequest,
  testJson,
  finallyUpdate,
  setHasError,
  hasError,
  prepareData,
  isTechnicianRole,
  currentUser,
  isProcess,
  isCancelledOrCompleted,
}) {
  const [loading, setLoading] = useState(false);
  const [containerTypes, setContainerTypes] = useState([]);
  const [containerStatuses, setContainerStatuses] = useState([]);
  const [availableUOMs, setAvailableUOMs] = useState([]);
  const [availableLocations, setAvailableLocations] = useState([]);
  const [containerErrorChecks, setContainerErrorChecks] = useState(
    workRequest.workRequestSamples.map((x, i) => ({
      ...newContainerErrorChecks,
    }))
  );
  const alreadyCallContainerOnce = useRef(false);

  const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
  const modalMessagesButtonText = 'Ok'
  const [modalMessagesTitle, setModalMessagesTitle] = useState('');
  const [modalMessagesText, setModalMessagesText] = useState('');

  const inputErrorAndDisabledStyle = {
    '& .MuiOutlinedInput-root.Mui-disabled.Mui-error': {
      '& fieldset': { borderColor: 'red' }
    }
  };

  setHasError(
    containerErrorChecks.some((containerError) => {
      return Object.keys(containerError).some((key) => {
        if (containerError[key]) return true;
        return false;
      });
    }) ?? false
  );

  useEffect(() => {
    let cancelPromise = false;

    Location.getAll().then((res) => {
      if (cancelPromise) return;
      setAvailableLocations(
        res
          .filter((result) => result.isActive === true)
          .sort((a, b) => a.locationName.localeCompare(b.locationName))
      );
    });

    UnitOfMeasure.getAll().then((res) => {
      if (cancelPromise) return;
      setAvailableUOMs(
        res
          .filter(
            (result) =>
              (result.metricStandardConversion !== null ||
                result.type === "concentration") &&
              result.isActive === true
          )
          .sort((a, b) => a.uoMName.localeCompare(b.uoMName))
      );
    });

    ContainerStatus.getAll().then((res) => {
      if (cancelPromise) return;
      setContainerStatuses(
        res
          .filter((result) => result.isActive === true)
          .sort((a, b) => a.name.localeCompare(b.name))
      );
    });

    return () => {
      cancelPromise = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setWorkRequest((prev) => ({
      ...prev,
      workRequestSamples: [
        ...prev.workRequestSamples.map((x) => {
          return {
            ...x,
            newContainer: x.newContainer ?? null,
          };
        }),
      ],
    }));
  }, [setWorkRequest]);

  useEffect(() => {
    workRequest?.workRequestSamples?.forEach((sample, index) => {
      const newContainer = sample?.newContainer;
      if (newContainer) {
        checkPropertyErrors(sample, index, newContainer);
      } 
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workRequest?.workRequestSamples]);

  useEffect(() => {
    if (
      !!testJson.methods?.length &&
      workRequest &&
      !alreadyCallContainerOnce.current
    ) {
      alreadyCallContainerOnce.current = true;
      handleGetTestContainers();
    }

    if(containerTypes.length === 0) {
        handleGetTestContainers();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testJson, workRequest]);

  function closeModalMessages() {
    setModalMessagesOpen(false);
  }

  function openModalMessages(title, text) {
    setModalMessagesOpen(true);
    setModalMessagesTitle(title);
    setModalMessagesText(text);
  }

  const existantValueOrDefault = (
    propertie,
    wrNewContainer,
    recommendationContainer
  ) => {
    if (wrNewContainer) return wrNewContainer[propertie];
    if (recommendationContainer) return recommendationContainer[propertie];

    return null;
  };

  async function handleGetTestContainers() {
    let types = await ContainerType.getAll();
    types = types
      .filter((result) => result.isActive === true)
      .sort((a, b) => a.name.localeCompare(b.name));
    setContainerTypes(types);

    let newTests = []
    if (testJson.methods.length > 0)
    {
      for (const oMethodInfo of testJson.methods)
      {
        const tests = await BuildTestingPayload(oMethodInfo, null, null, workRequest.chargeCode, workRequest.requestedByEmail)

        if (tests === null || typeof tests === 'string' || typeof tests[0] === 'string')
        {
          if (Array.isArray(tests))
          {
            openModalMessages("Error Building Tests", tests)        
            return
          } else {
            openModalMessages("Error Building Tests", `There was an error building the test payload. ${tests ? tests : ''}`)        
            return
          }
        }
        for (const oTest of tests)
        {
          newTests.push(oTest)  
        }
      }
    }

    const payload = {
      containeringMethod: testJson.containeringMethod,
      requireParentContainer: false,
      tests: newTests,
    };
    Container.GetContainersToCreate(payload)
      .then((result) => {
        if (result.message === 'Success')
        {
          if (!result.result) {
            return finallyUpdate(result.message);
          }
          let newWorkRequestSampleList = [];
          let newWorkRequest = structuredClone(workRequest);
          const helper =
            newWorkRequest.workRequestSamples.length / result.result.length - 1;
          let counter = 0;
          let containerIndex = 0;
          newWorkRequest.workRequestSamples.forEach((sample) => {
            const currentContainer = result.result[containerIndex];
            delete currentContainer?.containerType?.defaultContainerUoM;

            const currentAmount = sample.newContainer?.currentAmount ?? sample.requestedAmount ?? currentContainer?.currentAmount;
            const type = [...types].sort((a, b) => a.defaultContainerSize - b.defaultContainerSize).find(f => f.defaultContainerSize > currentAmount && f.defaultContainerUoMName === sample.requestedAmountUoM);
            const size = sample.newContainer?.size ?? type?.defaultContainerSize ?? currentContainer?.size; 
            newWorkRequestSampleList.push({
              ...sample,
              newContainer: {
                ...currentContainer,
                containerStatusName: "Confirmed",
                locationName: sample.newContainer?.locationName,
                parentContainer:
                  sample.newContainer?.parentContainer ??
                  sample.existingContainer ??
                  null,
                containerNumber: sample.newContainer?.containerNumber ?? 0,
                containerType: type ?? currentContainer?.containerType ?? sample.newContainer?.containerType,
                containerTypeName: type?.name ?? currentContainer?.containerTypeName ?? sample.newContainer?.containerTypeName,
                createdDate: sample.newContainer?.createdDate ?? null,
                ownerEmail:
                  sample.newContainer?.ownerEmail ??
                  newWorkRequest.requestedByEmail,
                subLocation: existantValueOrDefault(
                  "subLocation",
                  sample.newContainer,
                  currentContainer
                ),
                size: size,
                containerSizeUoM: existantValueOrDefault(
                  "containerSizeUoM",
                  sample.newContainer,
                  currentContainer
                ),
                currentAmount: currentAmount
              },
            });
            if (counter === helper) {
              counter = 0;
              containerIndex = ++containerIndex;
            } else {
              counter = ++counter;
            }
          });
          newWorkRequest.workRequestSamples = newWorkRequestSampleList;
  
          setWorkRequest(newWorkRequest);
          setContainerErrorChecks(
            newWorkRequestSampleList.map((x) => ({
              ...newContainerErrorChecks,
            }))
          );
        } else {
          openModalMessages("Error Getting Containers", `There was an error getting containers to create. ${result.message ? result.message : ''}`)
        }
        
      })
  }

  const isContainersAlreadyFetched = (sampleList) => {
    return sampleList.some((x) => x.newContainer?.containerNumber > 0);
  };

  const handleContainerRowChange = (property, value, index) => {
    let newWorkRequest = structuredClone(workRequest);
    let sample = newWorkRequest.workRequestSamples[index];
    let container = sample.newContainer;
    if (property === "containerSizeUoM") {
      if (value) {
        const targetUomObj = availableUOMs.find((x) => x.uoMName === value);
        const currentUomObj = availableUOMs.find(
          (x) => x.uoMName === container.containerSizeUoM
        );
        container.currentAmount = RecalculateCurrentAmountOnUoMChange(
          sample.sample,
          sample.sample.substance,
          container.currentAmount,
          currentUomObj,
          targetUomObj
        );
        container.size = RecalculateInitialSizeOnUoMChange(
          sample.sample,
          sample.sample.substance,
          container.size,
          currentUomObj,
          targetUomObj
        );
      }
    } else if (property === "containerTypeName") {
      const type = containerTypes.find(f => f.name === value);
      container['size'] = type?.defaultContainerSize ?? container['size'];
    }

    container[property] = value;
    setWorkRequest(newWorkRequest);
    checkPropertyErrors(sample, index, container);
  };

  const checkPropertyErrors = (sample, currentLine, newContainer) => {
    let containerError = newContainerErrorChecks;
    Object.keys(newContainer).forEach((key) => {
      if (key === "containerTypeName") {
        containerError[key] = !newContainer[key];
      }
      if (key === "containerStatusName") {
        containerError[key] = !newContainer[key];
      }
      if (key === "size") {
        containerError[key] =
          !newContainer[key] ||
          isNaN(newContainer[key]) ||
          newContainer.size < newContainer.currentAmount;
      }
      if (key === "currentAmount") {
        let minimumReq = newContainer.tests?.reduce(
          (acc, value) =>
            acc +
            ConvertUOMs(
              sample.sample,
              sample.sample.substance,
              GetRequiredSampleSize(value),
              availableUOMs.find(
                (x) => x.uoMName === GetRequiredSampleSizeUoM(value)
              ),
              availableUOMs.find(
                (x) => x.uoMName === newContainer.containerSizeUoM
              )
            ),
          0
        );
        containerError[key] =
          !newContainer[key] ||
          isNaN(newContainer[key]) ||
          newContainer.currentAmount > newContainer.size ||
          newContainer.currentAmount < minimumReq;
      }
      if (key === "containerSizeUoM") {
        containerError[key] = !newContainer[key];
      }
      if (key === "locationName") {
        containerError[key] = !newContainer[key];
      }
    });

    let newErrorCheck = structuredClone(containerErrorChecks);
    newErrorCheck[currentLine] = { ...containerError };
    setContainerErrorChecks(newErrorCheck);
  };

  const handleCreateContainers = () => {
    setLoading(true);
    let value = prepareData(workRequest);
    WorkRequest.createContainers(value)
      .then((res) => {
        setLoading(false);

        if (res && res.result)
        {
          let currentWr = structuredClone(workRequest);
          currentWr.workRequestSamples.forEach((sample) => {
            let currentNewContainer = res.result.workRequestSamples.find(
              (x) => x.id === sample.id
            ).newContainer;
            if (currentNewContainer && sample.newContainer) {
              sample.newContainer.containerNumber =
              currentNewContainer.containerNumber;
              sample.newContainer.createdDate = currentNewContainer.createdDate;
            }
          });
          setWorkRequest(currentWr);
        } else {
          openModalMessages("Error Creating Containers", `There was an error creating containers. ${res.message ? res.message : ''}`)
        }
      })
  };

  return (
    <Grid container>
      {!!testJson.methods?.length && (
        <LoadingButton
          variant="outlined"
          onClick={handleGetTestContainers}
          disabled={
            !testJson.methods ||
            isContainersAlreadyFetched(workRequest.workRequestSamples)
          }
          style={{ textTransform: "initial" }}
          loading={false}
        >
          Get Containers
        </LoadingButton>
      )}

      <UXDataTableWithoutBody
        tableWidth={"100%"}
        cols={
          !!testJson.methods?.length
            ? newContainersColWithTest
            : newContainersCol
        }
        blankFirstHeader={false}
        tableId="containerTable"
        rowLength={workRequest.workRequestSamples.length}
        enablePaging={false}
        rowsPerPage={0}
        page={0}
        handleChangePage={null}
        handleChangeRowsPerPage={null}
        noDataFoundMessage={"No containers found"}
        enableAddIcon={false}
        addFunction={false}
        addToolTipText={"Add Container"}
        isDataLoading={false}
      >
        <StyledTableBody>
          {workRequest.workRequestSamples.map((sample, index) => {
            let container = sample.newContainer;
            if (!container || !availableUOMs.length) return <></>;

            let minimumReq = container.tests?.reduce(
              (acc, value) =>
                acc +
                ConvertUOMs(
                  sample.sample,
                  sample.sample.substance,
                  GetRequiredSampleSize(value),
                  availableUOMs.find(
                    (x) =>
                      x.uoMName === GetRequiredSampleSizeUoM(value)
                  ),
                  availableUOMs.find(
                    (x) => x.uoMName === container.containerSizeUoM
                  )
                ),
              0
            );

            return (
              <TableRow key={`ContainerRow${index}`}>
                <StyledTableCell style={{ width: "15px" }}>
                  <b>{sample.sampleName}</b>
                </StyledTableCell>
                {!!testJson.methods?.length && (
                  <StyledTableCell>
                    {container.tests &&
                      container.tests.map((oTests) => {
                        return `${oTests.methodFacility?.methodName}/${oTests.methodFacility?.testFacilityAbv} `;
                      })}
                  </StyledTableCell>
                )}

                <StyledTableCell style={{ width: "15px" }}>
                  <b>{container.containerNumber}</b>
                </StyledTableCell>

                <StyledTableCell>
                  <StyledAutocomplete
                    renderOption={(props2, option) => (
                      <Option {...props2}>{option.containerNumber}</Option>
                    )}
                    disabled={!!container.createdDate || isCancelledOrCompleted}
                    disablePortal
                    noOptionsText={"No containers found"}
                    options={sample?.sample?.containers ? sample.sample.containers
                      .sort((a, b) => a.containerNumber - b.containerNumber)
                      .filter((c) => c.containerStatusName !== "Discarded") : []}
                    getOptionLabel={(option) =>
                      option.containerNumber.toString()
                    }
                    onChange={(e, value) =>
                      handleContainerRowChange("parentContainer", value, index)
                    }
                    autoHighlight
                    autoSelect
                    value={container.parentContainer}
                    isOptionEqualToValue={(option, value) =>
                      value.containerNumber === option.containerNumber
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "100px" }}
                        variant="outlined"
                        size="small"
                        inputProps={{
                          ...params.inputProps,
                          style: { fontSize: containerGridFontSize },
                        }}
                        InputProps={{ ...params.InputProps }}
                      />
                    )}
                  />
                </StyledTableCell>

                <StyledTableCell>
                  <StyledAutocomplete
                    renderOption={(props2, option) => (
                      <Option {...props2}>{option.name}</Option>
                    )}
                    disabled={!!container.createdDate || isCancelledOrCompleted}
                    disablePortal
                    autoHighlight
                    autoSelect
                    noOptionsText={"Loading Types..."}
                    options={containerTypes}
                    onChange={(e, value) => {
                      handleContainerRowChange(
                        "containerTypeName",
                        value?.name,
                        index
                      );
                    }}
                    getOptionLabel={(option) => option.name ?? option}
                    //onBlur={(e) => {updateErrorChecksForContainers('containerType', CheckAutoCompleteField(container, 'containerType'), index)}}
                    value={container.containerTypeName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "138px" }}
                        sx={inputErrorAndDisabledStyle}
                        variant="outlined"
                        size="small"
                        error={containerErrorChecks[index].containerTypeName}
                        inputProps={{
                          ...params.inputProps,
                          style: { fontSize: containerGridFontSize },
                        }}
                        InputProps={{ ...params.InputProps }}
                      />
                    )}
                  />
                </StyledTableCell>

                <StyledTableCell>
                  <StyledAutocomplete
                    renderOption={(props2, option) => (
                      <Option {...props2}>{option.name}</Option>
                    )}
                    getOptionLabel={(option) => option.name ?? option}
                    disabled={true}
                    disablePortal
                    noOptionsText={"Loading Statuses..."}
                    options={containerStatuses}
                    onChange={(e, value) =>
                      handleContainerRowChange(
                        "containerStatusName",
                        value?.name,
                        index
                      )
                    }
                    value={container.containerStatusName}
                    autoHighlight
                    autoSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "145px" }}
                        sx={inputErrorAndDisabledStyle}
                        variant="outlined"
                        size="small"
                        error={containerErrorChecks[index].containerStatusName}
                        inputProps={{
                          ...params.inputProps,
                          style: { fontSize: containerGridFontSize },
                        }}
                        InputProps={{ ...params.InputProps }}
                      />
                    )}
                  />
                </StyledTableCell>

                <StyledTableCell>
                  <TextField
                    style={{ width: "60px", marginTop: "5px" }}
                    sx={inputErrorAndDisabledStyle}
                    disabled={!!container.createdDate || isCancelledOrCompleted}
                    size="small"
                    margin="dense"
                    variant="outlined"
                    inputProps={{
                      style: { fontSize: containerGridFontSize },
                    }}
                    InputLabelProps={{ shrink: true }}
                    value={container.size}
                    error={containerErrorChecks[index].size}
                    onChange={(e) =>
                      handleContainerRowChange("size", e.target.value, index)
                    }
                    onBlur={(e) => {}}
                  />
                </StyledTableCell>

                <StyledTableCell>
                  <TextField
                    style={{ width: "60px", marginTop: "5px" }}
                    sx={inputErrorAndDisabledStyle}
                    disabled={!!container.createdDate || isCancelledOrCompleted}
                    size="small"
                    margin="dense"
                    variant="outlined"
                    inputProps={{
                      style: { fontSize: containerGridFontSize },
                    }}
                    InputLabelProps={{ shrink: true }}
                    value={container.currentAmount}
                    error={
                      containerErrorChecks[index].currentAmount ||
                      container.currentAmount < minimumReq
                    }
                    onChange={(e) =>
                      handleContainerRowChange(
                        "currentAmount",
                        e.target.value,
                        index
                      )
                    }
                    onBlur={(e) => {}}
                  />
                </StyledTableCell>

                {/* UoM */}
                <StyledTableCell>
                  <StyledAutocomplete
                    renderOption={(props2, option) => (
                      <Option {...props2}>{option.uoMName}</Option>
                    )}
                    disabled={!!container.createdDate || isCancelledOrCompleted}
                    disablePortal
                    noOptionsText={"Loading UOMs..."}
                    options={availableUOMs.filter(
                      (result) =>
                        result.type === "weight" ||
                        result.type === "volume" ||
                        result.type === "unit"
                    )}
                    getOptionLabel={(option) => option.uoMName ?? option}
                    onChange={(e, value) => {
                      handleContainerRowChange(
                        "containerSizeUoM",
                        value?.uoMName,
                        index
                      );
                    }}
                    value={container.containerSizeUoM}
                    autoHighlight
                    autoSelect
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "80px" }}
                        sx={inputErrorAndDisabledStyle}
                        variant="outlined"
                        size="small"
                        error={containerErrorChecks[index].containerSizeUoM}
                        inputProps={{
                          ...params.inputProps,
                          style: { fontSize: containerGridFontSize },
                        }}
                        InputProps={{ ...params.InputProps }}
                      />
                    )}
                  />
                </StyledTableCell>

                <StyledTableCell>
                  {container.size > 0
                    ? (
                        (container.currentAmount / container.size) *
                        100
                      ).toFixed(2)
                    : 0}
                  %
                </StyledTableCell>

                <StyledTableCell>
                  <StyledAutocomplete
                    disabled={isCancelledOrCompleted}
                    value={container.locationName}
                    onChange={(e, value) =>
                      handleContainerRowChange(
                        "locationName",
                        value?.locationName,
                        index
                      )
                    }
                    disablePortal
                    autoHighlight
                    autoSelect
                    options={availableLocations}
                    renderOption={(props2, option) => (
                      <Option {...props2}>{option.locationName}</Option>
                    )}
                    getOptionLabel={(option) => option.locationName ?? option}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        style={{ width: "140px" }}
                        variant="outlined"
                        size="small"
                        error={containerErrorChecks[index].locationName}
                        inputProps={{
                          ...params.inputProps,
                          style: { fontSize: containerGridFontSize },
                        }}
                        InputProps={{ ...params.InputProps }}
                      />
                    )}
                  />
                </StyledTableCell>

                <StyledTableCell>
                  <TextField
                    disabled={isCancelledOrCompleted}
                    style={{ width: "150px", marginTop: "5px" }}
                    size="small"
                    margin="dense"
                    variant="outlined"
                    InputProps={{
                      style: { fontSize: containerGridFontSize },
                      maxLength: 4000,
                    }}
                    InputLabelProps={{ shrink: true }}
                    value={container.subLocation ?? ""}
                    onChange={(e) =>
                      handleContainerRowChange(
                        "subLocation",
                        e.target.value,
                        index
                      )
                    }
                  />
                </StyledTableCell>

                <StyledTableCell>
                  <EmailAddressTextField
                    fontSize={12}
                    fieldWidth={"225px"}
                    validatedUserEmail={container.ownerEmail}
                    setValidatedUserEmail={(e) =>
                      handleContainerRowChange("ownerEmail", e, index)
                    }
                    hasErrors={
                      containerErrorChecks[index]?.ownerEmail ||
                      !container.ownerEmail
                    }
                    setHasErrors={(e) => {}}
                    isDisabled={!!container.createdDate || isCancelledOrCompleted}
                    labelText="Owner Email"
                    placeholderText="Owner Email"
                    showPlusMeButton={false}
                  ></EmailAddressTextField>
                </StyledTableCell>
              </TableRow>
            );
          })}
        </StyledTableBody>
      </UXDataTableWithoutBody>
      <GlobalButton
        disabled={
          !isTechnicianRole ||
          hasError ||
          isContainersAlreadyFetched(workRequest.workRequestSamples) ||
          workRequest.workRequestSamples.some((x) => !x.newContainer) ||
          !isProcess ||
          loading
        }
        variant="contained"
        onClick={handleCreateContainers}
      >
        Create Containers
        {loading ? (
          <CircularProgress
            sx={{ marginLeft: "10px", color: "white" }}
            size={20}
          />
        ) : (
          ""
        )}
      </GlobalButton>

       {/* Informational Messages */}
      <ModalMessages title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
        <label>
          {modalMessagesText}
        </label>
      </ModalMessages>
    </Grid>
  );
}
