
import { useEffect } from "react";
import {
  SubSectionTitle,
  labelProp,
  defaultErrorsProductDevelopmentRelease,
  SubTitle,
  defaultProductDevelopmentRelease,
} from "./constants";
import { SDSFormAutocomplete, SDSFormInput } from "./SDSFormInput";
import { removeMatchingProperties } from "../../../../global";
import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";

const ProductDevelopmentRelease = ({ commonProps, disabled, syntheticIndicators, syntheticIndicatorsLoading }) => {
  const { setErrors, data, setData } = commonProps;

  useEffect(() => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      ...defaultErrorsProductDevelopmentRelease
    }))

    return () => {
      setErrors((prevErrors) => (removeMatchingProperties(prevErrors, defaultErrorsProductDevelopmentRelease)))
    };
  }, [setErrors])

  useEffect(() => {
    return () => {
      setData((prevData) => (removeMatchingProperties(prevData, defaultProductDevelopmentRelease)))
    };
  }, [setData])

  return (
    <div>
      <SubSectionTitle>SDS For Product Development - Release</SubSectionTitle>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled}
          label="List Parent Product Name"
          property="parentProductName"
        />
      </div>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isModeledAfterExistingProduct}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isModeledAfterExistingProduct: e.target.checked
          }))}
          label={<>Model After Existing Product? (Attach SDS for components not in the system)</>}
        />
      </div>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled}
          label="Provide Closest ProMIS Technical Spec"
          property="existingTechnicalSpec"
        />
      </div>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isNewSDS}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isNewSDS: e.target.checked
          }))}
          label={<>New SDS?</>}
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
          height: 60
        }}
      >
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isCommercialFlagshipProduct}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isCommercialFlagshipProduct: e.target.checked
          }))}
          label={<>Is this a Commercial Flagship product?</>}
        />

        <SDSFormAutocomplete
          {...commonProps}
          disabled={disabled}
          styled={{ marginBottom: 0 }}
          options={syntheticIndicators}
          property={'syntheticIndicatorName'}
          label={'Synthetic Indicator in ProMIS'}
          noOptionsText={
            syntheticIndicatorsLoading
              ? "Loading Synthetic Indicators..."
              : "No Synthetic Indicators Found"
          }
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1rem",
          height: 60
        }}
      >
        <SDSFormInput
          {...commonProps}
          disabled={disabled}
          label={labelProp['ecrNumber']}
          property="ecrNumber"
          required
        />

        <SDSFormInput
          {...commonProps}
          disabled={disabled}
          label={labelProp['ph6Code']}
          property="ph6Code"
          required
        />
      </div>
      <SubTitle>Regions where product will be shipped and/or marketed:</SubTitle>
      <FormGroup row>
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isNorthAmerica}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isNorthAmerica: e.target.checked
          }))}
          label={<>North America</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isSouthAmerica}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isSouthAmerica: e.target.checked
          }))}
          label={<>South America</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isEurope}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isEurope: e.target.checked
          }))}
          label={<>Europe</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isAfrica}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isAfrica: e.target.checked
          }))}
          label={<>Africa/Middle East</>}
        />
        <FormControlLabel
          control={<Checkbox />}
          disabled={disabled}
          checked={data.isAsiaPacific}
          onChange={(e) => setData((prevData) => ({
            ...prevData,
            isAsiaPacific: e.target.checked
          }))}
          label={<>Asia Pacific</>}
        />
      </FormGroup>
      <div style={{ display: "flex", gap: "15px", alignItems: "baseline" }}>
        <SDSFormInput
          {...commonProps}
          disabled={disabled}
          label="If country-specific SDSes are required, please specify"
          property="countrySpecificInfo"
        />
      </div>
    </div>
  );
};

export default ProductDevelopmentRelease;
