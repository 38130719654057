import { createContext, useState } from "react";
import Requests from "../../../api/SDSRequest/SDSRequest";

export const SDSRequestContext = createContext(null)

export const SDSRequestContextProvider = ({ children }) => {
  const [requests, setRequests] = useState([])

  const get = async () => {
    const data = await Requests.get();
    setRequests(data);
  }

  return (
    <SDSRequestContext.Provider value={{ requests, get }}>
      {children}
    </SDSRequestContext.Provider>
  )
}