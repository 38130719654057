import "./style.css";
import { convertDateFormat, RoundNumber, GetRequiredSampleSize, GetRequiredSampleSizeUoM } from "../../../global";

const BlendPrepSheet = ({myBatchInfo, myMethodInfo, toPrepareUoM, toPrepareValue}) => {

    const decimalsToRound = (myBatchInfo.batchPrecision ? myBatchInfo.batchPrecision : 3)

    function DetermineUoMDisplay (oComponent)
    {
      if (oComponent.componentUoMName === 'wt%' || oComponent.componentUoMName === 'vol%')
      {
        return (toPrepareUoM !== null ? toPrepareUoM.uoMName : '')
      } else {
        return (oComponent.componentUoMName)
      }
    }

    // function DetermineQtyRequestedDisplay ()
    // {
    //     const substanceObj = myBatchInfo.blendRequest?.blendSubstance ? myBatchInfo.blendRequest.blendSubstance : null

    //     if (toPrepareUoM)
    //     {
    //         return `${RoundNumber(ConvertUOMs(null, substanceObj, myBatchInfo.requestedAmount, myBatchInfo.unitOfMeasureRequested, toPrepareUoM), decimalsToRound)} ${toPrepareUoM.uoMName}`

    //     } else {
    //         return `${myBatchInfo.requestedAmount}${myBatchInfo.requestedAmountUoM}`
    //     }
    // }

    function GetRequestedUOMDisplay (componentInfo) {
        if (componentInfo.componentUoMName === 'wt%' || componentInfo.componentUoMName === 'vol%')
        {
          if (toPrepareUoM)
          {
            if (toPrepareUoM.type === 'weight') {
              return 'wt%'
            } else {
              return 'vol%'
            }
          } else {
            return 'wt%'
          }
        } else {
          return componentInfo.componentUoMName
        }
    }

    return (
    <div>
        <div className="container" id='blend-form-print'>

        <div style={{display: "flex", width: "100%"}}>
             <h1 className="minorHeader" style={{textAlign: "left"}}>Proprietary</h1>
            <h1 className="minorHeader" style={{textAlign: "right"}}>Blend Preperation Report</h1>
        </div>

        <div style={{ borderTop: '4px solid black', width: '100%' }} />

            <table className="tableTop">
                <tbody>
                    <tr>
                        <td>
                            <span className="topMenuItemHead">Blend /Batch</span>
                            <span className="topMenuItemData">{myBatchInfo && myBatchInfo.blendRequest ? `${myBatchInfo.blendRequest.blendStudy.studyName}_${myBatchInfo.blendRequest.blendName}/${myBatchInfo.batchSequenceNumber}` : '-'}</span>
                        </td>
                        <td>
                            <span className="topMenuItemHead">Preparation Status</span>
                            <span className ="topMenuItemData">{myBatchInfo.batchStatusName}</span>
                        </td>
                        <td>
                            <span className="topMenuItemHead">Metals Status</span>
                            <span className="topMenuItemData">{myBatchInfo.isMetalsFree ? 'No Metals' : 'Contains Metals'}</span>
                        </td>
                    </tr>

                    <tr>
                        <td className="topMenuTableRow">
                            <span className="topMenuItemHead">ISO Status</span>
                            <span className="topMenuItemData">{myBatchInfo.isISOBlend ? 'ISO Blend' : '-'}</span>
                        </td>
                        <td className="topMenuTableRow">
                            <span className="topMenuItemHead">Description</span>
                            <span className ="topMenuItemData">{myBatchInfo.description ? myBatchInfo.description : '-'}</span>
                        </td>
                    </tr>

                    <tr>
                        <td className="topMenuTableRow">
                            <span className="topMenuItemHead">Date Created</span>
                            <span className="topMenuItemData">{convertDateFormat(myBatchInfo.createdDate)}</span>
                        </td>
                        <td className="topMenuTableRow">
                            <span className="topMenuItemHead">Purpose</span>
                            <span className ="topMenuItemData">{myBatchInfo.batchPurpose ? myBatchInfo.batchPurpose : '-'}</span>
                        </td>
                    </tr>

                    <tr>
                        <td className="topMenuTableRow">
                            <span className="topMenuItemHead">Date Modified</span>
                            <span className="topMenuItemData">{convertDateFormat(myBatchInfo.lastModifiedDate)}</span>
                        </td>
                        <td className="topMenuTableRow">
                            <span className="topMenuItemHead">Qty to Prepare</span>
                            <span className ="topMenuItemData">{toPrepareValue ? `${toPrepareValue} ${toPrepareUoM ? toPrepareUoM.uoMName : "???"}`: "???"}</span>
                            {/* <span className ="topMenuItemData">{DetermineQtyRequestedDisplay()}</span> */}
                        </td>
                    </tr>

                    <tr>
                        <td className="topMenuTableRow">
                            <span className="topMenuItemHead">MIDAS Number</span>
                            <span className="topMenuItemData">{myBatchInfo.preparedSampleName ? myBatchInfo.preparedSampleName : '-'}</span>
                        </td>
                        

                        <td className="topMenuTableRow">
                            <span className="topMenuItemHead">Reference</span>
                            <span className ="topMenuItemData">{myBatchInfo.reference ? myBatchInfo.reference : '-' }</span>
                        </td>
                    </tr>

                    <tr>
                        <td className="topMenuTableRow">
                            <span className="topMenuItemHead">Owner</span>
                            <span className="topMenuItemData">{myBatchInfo.blendRequest?.blendStudy.ownerEmail ? myBatchInfo.blendRequest?.blendStudy.ownerEmail : '-' }</span>
                        </td>
                        <td className="topMenuTableRow">
                            <span className="topMenuItemHead">Product Group</span>
                            <span className ="topMenuItemData">{myBatchInfo.batchProductGroupName}</span>
                        </td>
                    </tr>

                    <tr>
                        <td className="topMenuTableRow">
                            <span className="topMenuItemHead">Assigned Blender</span>
                            <span className="topMenuItemData">{myBatchInfo.preparedByEmail ? myBatchInfo.preparedByEmail : '-' }</span>
                        </td>
                        <td className="topMenuTableRow">
                            <span className="topMenuItemHead">Project</span>
                            <span className ="topMenuItemData">{myBatchInfo.blendRequest?.blendStudy.project.name}</span>
                        </td>
                        <td className="topMenuTableRow">
                            <span className="topMenuItemHead">Special Handling</span>
                            <span className ="topMenuItemData">{myBatchInfo.safetyInformation ? myBatchInfo.safetyInformation : '-' }</span>
                        </td>
                    </tr>

                    <tr>
                        <td className="topMenuTableRow">
                            <span className="topMenuItemHead">Charge Code</span>
                            <span className="topMenuItemData">{myBatchInfo.chargeCode ? myBatchInfo.chargeCode : '-'}</span>
                        </td>
                        <td className="topMenuTableRow">
                            <span className="topMenuItemHead">Shipping Info</span>
                            <span className ="topMenuItemData">{myBatchInfo.shippingInfo ? myBatchInfo.shippingInfo : '-'}</span>
                        </td>
                    </tr>
                    <tr>
                        <td className="topMenuTableRow">
                            <span className="topMenuItemHead">Comments</span>
                            <span className="topMenuItemData">{myBatchInfo.batchComments ? myBatchInfo.batchComments : '-'}</span>
                        </td>
                    </tr>
                </tbody>
            </table>

            <table className="tableMethods" cellSpacing="0">
                <tbody>
                    <tr style={{height:"20pt"}}>
                        <td className="tdTableMethods" colSpan="9">
                            <span className="tableMethodsHeaderData">Blend Formulation</span>
                        </td>
                    </tr>
                    <tr style={{height:"20pt"}}>
                        <td className="tdTableMethods_minor">
                            <span className="tableMethodsHeaderData_minor">#</span>
                        </td>
                        <td className="tdTableMethods_minor">
                            <span className="tableMethodsHeaderData_minor">Component</span>
                        </td>
                        <td className="tdTableMethods_minor">
                            <span className="tableMethodsHeaderData_minor">MIDAS Numbers</span>
                        </td>
                        <td className="tdTableMethods_minor">
                            <span className="tableMethodsHeaderData_minor">To Prepare</span>
                        </td>
                        <td className="tdTableMethods_minor">
                            <span className="tableMethodsHeaderData_minor">Min</span>
                        </td>
                        <td className="tdTableMethods_minor">
                            <span className="tableMethodsHeaderData_minor">Actual</span>
                        </td>
                        <td className="tdTableMethods_minor">
                            <span className="tableMethodsHeaderData_minor">Max</span>
                        </td>
                        <td className="tdTableMethods_minor">
                            <span className="tableMethodsHeaderData_minor">Requested</span>
                        </td>
                        <td className="tdTableMethods_minor">
                            <span className="tableMethodsHeaderData_minor">Chem ID</span>
                        </td>
                    </tr>

                    {myBatchInfo.blendRequest?.blendComponents.map((oComponent, index) => {
                    return(
                        <tr style={{height:"25pt"}}  key={`componentsTable_row${index}`}>
                            <td>
                                <span className="tableMethods_data">{index + 1}</span>
                            </td>
                            <td>
                                <span className="tableMethods_data">{oComponent.substance.substanceName}</span>
                            </td>
                            <td>
                                <span className="tableMethods_data">{`${oComponent.preferredSampleName ? `${oComponent.preferredSampleName}, ${oComponent.secondaryPreferredSampleName ? oComponent.secondaryPreferredSampleName : '-'} ` : '-'}`}</span>
                            </td>
                            <td>
                                {/* <span className="tableMethods_data">{`${RoundNumber(oComponent.toPrepareValue, decimalsToRound)} ${DetermineUoMDisplay(oComponent)}`}</span> */}
                                <span className="tableMethods_data">{`${RoundNumber(oComponent.toPrepareValue, Number(oComponent.toPrepareValue) < 1 ? decimalsToRound + 1 : decimalsToRound)} ${DetermineUoMDisplay(oComponent)}`}</span>

                            </td>
                            <td>
                                {/* <span className="tableMethods_data">{RoundNumber(oComponent.toPrepareValue - (oComponent.toPrepareValue * (myBatchInfo.blendFacility.preparationThresholdPercent / 100)), decimalsToRound)}</span> */}
                                <span className="tableMethods_data">{RoundNumber(oComponent.toPrepareValue - (oComponent.toPrepareValue * (myBatchInfo.blendFacility.preparationThresholdPercent / 100)), Number(oComponent.toPrepareValue) < 1 ? decimalsToRound + 1 : decimalsToRound)}</span>

                            </td>
                            <td>
                            <span className="tableMethods_data"></span>
                            </td>
                            <td>
                            {/* <span className="tableMethods_data">{RoundNumber(oComponent.toPrepareValue + (oComponent.toPrepareValue * (myBatchInfo.blendFacility.preparationThresholdPercent / 100)), decimalsToRound)}</span> */}
                            <span className="tableMethods_data">{RoundNumber(oComponent.toPrepareValue + (oComponent.toPrepareValue * (myBatchInfo.blendFacility.preparationThresholdPercent / 100)), Number(oComponent.toPrepareValue) < 1 ? decimalsToRound + 1 : decimalsToRound)}</span>

                            </td>
                            <td>
                                <span className="tableMethods_data">{`${RoundNumber(oComponent.targetAmountLower, decimalsToRound)} ${GetRequestedUOMDisplay(oComponent)}`}</span> 
                                {/* ${oComponent.componentUoMName}`}</span> */}
                            </td>
                            <td>
                                <span className="tableMethods_data">{oComponent.substanceName}</span>
                            </td>
                        </tr>
                    )})
                    }
                </tbody>
            </table>

            <table className="tableMethods" cellSpacing="0">
                <tbody>
                    <tr style={{height:"20pt"}}>
                        <td className="tdTableMethods" colSpan="7">
                            <span className="tableMethodsHeaderData">Methods</span>
                        </td>
                    </tr>
                    <tr style={{height:"20pt"}}>
                        <td className="tdTableMethods_minor">
                            <span className="tableMethodsHeaderData_minor">#</span>
                        </td>
                        <td className="tdTableMethods_minor">
                            <span className="tableMethodsHeaderData_minor">Method</span>
                        </td>
                        <td className="tdTableMethods_minor">
                            <span className="tableMethodsHeaderData_minor">Condition</span>
                        </td>
                        <td className="tdTableMethods_minor">
                            <span className="tableMethodsHeaderData_minor">Estimate</span>
                        </td>
                        <td className="tdTableMethods_minor">
                            <span className="tableMethodsHeaderData_minor">Req. Size</span>
                        </td>
                    </tr>

                    {myMethodInfo.map((oMethodInfo, index) => {
                    return(
                        <tr style={{height:"25pt"}} key={`methodsTable_row${index}`}>
                            <td>
                                <span className="tableMethods_data">{index + 1}</span>
                            </td>
                            <td>
                                <span className="tableMethods_data">{`${oMethodInfo.method.name}${oMethodInfo.methodFacility ? "/" + oMethodInfo.methodFacility.testFacilityAbv : ""}`}</span>
                            </td>
                            <td>
                                <span className="tableMethods_data">{oMethodInfo.conditionOffering ? oMethodInfo.conditionOffering.conditionOfferingName : ''}</span>
                            </td>
                            <td>
                                <span className="tableMethods_data">{`${oMethodInfo.estimate && oMethodInfo.estimate !== '' ? oMethodInfo.estimate : '-'}`}</span>
                            </td>
                            <td>
                                <span className="tableMethods_data">{`${GetRequiredSampleSize(oMethodInfo)} ${GetRequiredSampleSizeUoM(oMethodInfo)}`}</span>
                            </td>
                        </tr>
                    )})
                    }
                </tbody>
            </table>
        </div>
    </div>
    )
}

export default BlendPrepSheet;