import API from "..";
export default class PiscesBatch {
    /**
     * The Container dimension table for M3
     * @param TODO
     */
    constructor({
      priority,
      submittedDate,
      requestedCompletionDate,
      preparedDate,
      daysBacklogged,
      //daysOnHold,
      blendStudyName,
      blendName,
      batchSequenceNumber,
      preparedByEmail,
      //description,
      //blendPurpose,
      projectName,
      requestedAmount,
      requestedAmountUoM,
      numberComponents,
      //billingInfo,
      requester,
      blendFacilityName,
      preparedSampleName,
      batchStatusName,
      batchProductGroupName,
      chargeCode
    }) {
      //this.blendProductGroupName = blendProductGroupName;
      this.priority = priority;
      this.submittedDate = submittedDate;
      this.requestedCompletionDate = requestedCompletionDate;
      this.preparedDate = preparedDate;
      this.daysBacklogged = daysBacklogged;
      //this.daysOnHold = daysOnHold;
      this.blendStudyName = blendStudyName;
      this.blendName = blendName;
      this.batchSequenceNumber = batchSequenceNumber;
      this.preparedByEmail = preparedByEmail;
      //this.description = description;
      //this.blendPurpose = blendPurpose;
      this.projectName = projectName;
      this.requestedAmount = requestedAmount;
      this.requestedAmountUoM = requestedAmountUoM;
      this.numberComponents = numberComponents;
      //this.billingInfo = billingInfo;
      this.requester = requester;
      this.blendFacilityName = blendFacilityName;
      this.preparedSampleName = preparedSampleName
      this.batchStatusName = batchStatusName;
      this.batchProductGroupName = batchProductGroupName;
      this.chargeCode = chargeCode;
    }


    static async get(startDate, endDate, blendFacilities, page, resultsPerPage) {
      const queryString = blendFacilities.join('&blendFacilities=');
      const api = await API();
      const { data } = await api.get(`/piscesreporting/blend?startDate=${startDate}&endDate=${endDate}&page=${page}&resultsPerPage=${resultsPerPage}${queryString ? '&blendFacilities=' + queryString : ''}`);
      return data.result.map((d) => new PiscesBatch(d));
    }

}