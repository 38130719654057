export {};

declare global {
  interface Array<T> {
    compactMap<U>(callback: (value: T, index: number, array: T[]) => U | null | undefined): U[];
  }
}

if (!Array.prototype.compactMap) {
  // eslint-disable-next-line no-extend-native
  Array.prototype.compactMap = function <T, U>(
    this: T[],
    callback: (value: T, index: number, array: T[]) => U | null | undefined,
  ): U[] {
    return this.map(callback).filter((value): value is U => value !== null && value !== undefined);
  };
}
