import { useState, useEffect } from "react";
import {useLocation} from 'react-router-dom';
import ModalNoButton from "../../../components/Modal/ModalNoButton";
import ModalSimpleButton from "../../../components/Modal/ModalSimpleButton";
import DistillationExperiment from "../../../api/Distillation/DistillationExperiment";
import DistillationCut from "../../../api/Distillation/DistillationCut";
import VacuumDistillation from "./VacuumDistillation";
import AtmosphericDistillation from "./AtmosphericDistillation";
import ExtractionDistillation from "./ExtractionDistillation";
import {ConvertUOMs, EvaluateRequiredNumberField, RoundNumber, isNumber, BuildTestingPayload} from "../../../global";
import Location from "../../../api/Admin/Location";
import UnitOfMeasure from "../../../api/Admin/UnitOfMeasure";
import Container from "../../../api/LIMS/Container";
import PrintInformation from "../../../components/PrintInformation";
import ContainerType from "../../../api/LIMS/ContainerType";
import DistillationCutType from "../../../api/Distillation/DistillationCutType";
//import UserContext from "../../../context/UserContext";

const errorsModalTitle = "Errors Detected"

const StartExperiment = () => {
    const location = useLocation()
    const incomingExperimentID  = location.state && location.state.incomingExperimentID ? location.state.incomingExperimentID : -1
    const [myDistillationExp, setMyDistillationExp] = useState([])
    const [cutErrors, setCutErrors] = useState([])
    const [experimentErrors, setExperimentErrors] = useState({experimentLocation:null, atmosphericStillName:null, vacuumStillName:null, waterWeight:null, atmosphericWettage:false, vacuumWettage:false, atmosphericPotSizeL:null, vacuumPotSizeL:null, actualInitialSampleAmount:false})
    
    const [allLocations, setAllLocations] = useState(null)
    const [availableUOMs, setAvailableUOMs] = useState([])
    const [availableCutTypes, setAvailableCutTypes] = useState(null)
    const [actualAmountMessage, setActualAmountMessage] = useState("Only Numbers")
  
    const [modalNoButtonOpen, setModalNoButtonOpen] = useState(false);
    const [modalNoButtonTitle, setModalNoButtonTitle] = useState('');
    const [modalNoButtonText, setModalNoButtonText] = useState('');

    const [modalMessagesOpen, setModalMessagesOpen] = useState(false);
    const modalMessagesButtonText = 'Ok'
    const [modalMessagesTitle, setModalMessagesTitle] = useState('');
    const [modalMessagesText, setModalMessagesText] = useState('');

    const atmosphericExperimentHasStarted = myDistillationExp?.atmosphericStartDate !== null
    const vacuumExperimentHasStarted = myDistillationExp?.vacuumStartDate !== null
    const extractionExperimentHasStarted = myDistillationExp?.extractionStartDate !== null

    const atmosphericExperimentResultsEntered = myDistillationExp?.atmosphericEndDate !== null
    const vacuumExperimentResultsEntered = myDistillationExp?.vacuumEndDate !== null
    const extractionExperimentResultsEntered = myDistillationExp?.extractionEndDate !== null

    const atmosphericExperimentHasEnded = myDistillationExp?.atmosphericEndDate !== null

    const [vacuumExperimentIsStarting, setVacuumExperimentIsStarting] = useState(false)
    const [atmosphericExperimentIsStarting, setAtmosphericExperimentIsStarting] = useState(false)
    const [extractionExperimentIsStarting, setExtractionExperimentIsStarting] = useState(false)

    const [vacuumExperimentResultsAreSaving, setVacuumExperimentResultsAreSaving] = useState(false)
    const [atmosphericExperimentResultsAreSaving, setAtmosphericExperimentResultsAreSaving] = useState(false)
    const [extractionExperimentResultsAreSaving, setExtractionExperimentResultsAreSaving] = useState(false)

    const [vacuumExperimentTestsAreSaving, setVacuumExperimentTestsAreSaving] = useState(false)
    const [atmosphericExperimentTestsAreSaving, setAtmosphericExperimentTestsAreSaving] = useState(false)
    const [extractionExperimentTestsAreSaving, setExtractionExperimentTestsAreSaving] = useState(false)

    const [reloadExp, setReloadExp] = useState(false)

    const [testingContainers, setTestingContainers] = useState([])
    const [testingContainerErrors, setTestingContainerErrors] = useState([])

    const [printInfoOpen, setPrintInfoOpen] = useState(false);
    const [printLabelInfo, setPrintLabelInfo] = useState(null)
    const [printModalButton2Text, setPrintModalButtonTwoText] = useState(null)

    const [containerTypes, setContainerTypes] = useState([])

    //const currentUser = useContext(UserContext)
    //const roles = currentUser?.idTokenClaims.roles;
    //const hasDistillationCorrector = hasRole(Roles.DistillationCorrector, roles) || hasRole(Roles.Developer, roles)


     useEffect (() => {
        if (incomingExperimentID > -1)
        {
          let cancelPromise = false
          
          openModalNoButton("Loading Distillation Data", "Please wait while distaillation data is loading...")
    
          DistillationExperiment.getExperimentById(incomingExperimentID).then((res) => {
    
            closeModalNoButton()
    
            if (cancelPromise) return
    
            if (res.message === "Success")
            {
               if (res.result.actualInitialSampleAmount === null)
               {
                    res.result.actualInitialSampleAmount = res.result.initialSampleAmount
               }

               if (res.result.distillationTypeName === 'Vacuum') 
               {
                    const vacuumWholeCut = res.result?.experimentCuts?.find(cut => cut.thisDistillationCutType.isStartingCut === true && cut.thisDistillationCutType.isHighVacuum === true)
                    const myIndex = res.result.experimentCuts.findIndex(cut => cut.id === vacuumWholeCut.id)

                    res.result.experimentCuts[myIndex].distillationCutYieldInG = res.result.actualInitialSampleAmount
                    res.result.experimentCuts[myIndex].thisContainer = res.result.initialContainer

               } else if (res.result.distillationTypeName === 'Atmospheric')
               {
                    const atmosphereWholeCut = res.result?.experimentCuts?.find(cut => cut.thisDistillationCutType.isStartingCut === true && cut.thisDistillationCutType.isAtmospheric === true)
                    const myIndex = res.result.experimentCuts.findIndex(cut => cut.id === atmosphereWholeCut.id)

                    res.result.experimentCuts[myIndex].distillationCutYieldInG = res.result.actualInitialSampleAmount
                    res.result.experimentCuts[myIndex].thisContainer = res.result.initialContainer

               } else if (res.result.distillationTypeName === 'Atmospheric/Vacuum')
               {
                    const atmosphereWholeCut = res.result?.experimentCuts?.find(cut => cut.thisDistillationCutType.isStartingCut === true && cut.thisDistillationCutType.isAtmospheric === true)
                    const myIndexWhole = res.result.experimentCuts.findIndex(cut => cut.id === atmosphereWholeCut.id)

                    res.result.experimentCuts[myIndexWhole].distillationCutYieldInG = res.result.actualInitialSampleAmount
                    res.result.experimentCuts[myIndexWhole].thisContainer = res.result.initialContainer

                    const atmosphereResidCut = res.result?.experimentCuts?.find(cut => cut?.distillationCutTypeName === 'Atmospheric Resid')
                    const vacuumWholeCut = res.result?.experimentCuts?.find(cut => cut.thisDistillationCutType.isStartingCut === true && cut.thisDistillationCutType.isHighVacuum === true)
                    const myIndexVacuumWhole = res.result.experimentCuts.findIndex(cut => cut.id === vacuumWholeCut.id)

                    res.result.experimentCuts[myIndexVacuumWhole].distillationCutYieldInG = atmosphereResidCut.distillationCutYieldInG
                    res.result.experimentCuts[myIndexVacuumWhole].thisContainer = atmosphereResidCut.thisContainer

               } else if (res.result.distillationTypeName === 'Extractions')
               {
                    const extractionWholeCut = res.result?.experimentCuts?.find(cut => cut.thisDistillationCutType.isStartingCut === true && cut.thisDistillationCutType.isExtraction === true)
                    const myIndex = res.result.experimentCuts.findIndex(cut => cut.id === extractionWholeCut.id)

                    res.result.experimentCuts[myIndex].distillationCutYieldInG = res.result.actualInitialSampleAmount
                    res.result.experimentCuts[myIndex].thisContainer = res.result.initialContainer
               }

               setMyDistillationExp(res.result)

               var tempCutErrors = []

               res.result.experimentCuts.forEach(oCut => {
                    tempCutErrors.push({
                         id:oCut.id,
                         actualStartingTemperatureC:false,
                         actualEndTemperatureC:false,
                         pressure:false, 
                         potLiquidTemperatureC:false,
                         distillationCutYieldInG: (oCut.thisDistillationCutType.isStartingCut === true || oCut.distillationCutStatusName === 'Failed') ? false : EvaluateRequiredNumberField(oCut.distillationCutYieldInG) ? null : false,
                         densityYieldPercent:false,
                         riYieldPercent:false,
                         averageYieldPercent:false,
                         carryUnderYieldPercent:false,
                         numberExtractionStages:false,
                         extractSolventWeightPercent:false,
                         raffinateSolventWeightPercent:false,
                         solventWaterWeightPercent:false,
                         towerBottomTemperatureC:false,
                         towerTopTemperatureC:false,
                         dewaxedPercent:false,
                         raffinatePercent:false
                    })      
               });

               setCutErrors(tempCutErrors)
               setExperimentErrors({experimentLocation: res.result.experimentLocation === null ? null : false,
                    atmosphericStillName: res.result.distillationTypeName.includes("Atmospheric") ? null : false,
                    vacuumStillName: res.result.distillationTypeName.includes("Vacuum") ? null : false,
                    waterWeight: res.result.distillationTypeName.includes("Vacuum") || res.result.distillationTypeName.includes("Atmospheric") ? null : false, 
                    atmosphericWettage: res.result.distillationTypeName.includes("Atmospheric") ? null : false, 
                    vacuumWettage: res.result.distillationTypeName.includes("Vacuum") ? null : false, 
                    atmosphericPotSizeL:res.result.distillationTypeName.includes("Atmospheric") ? null : false, 
                    vacuumPotSizeL:res.result.distillationTypeName.includes("Vacuum") ? null : false, 
                    actualInitialSampleAmount:false,
                    extractionUnit: res.result.distillationTypeName.includes("Extractions") ? null : false,})

               if (res.result.distillationStatusName !== 'Completed' && res.result.distillationStatusName !== 'Cancelled')
               {
                    switch (res.result.distillationTypeName) {
                         case 'Atmospheric':
                              if (res.result.atmosphericEndDate !== null)
                              {
                                   GetContainering(res.result)
                              }
                              break;
          
                         case 'Vacuum':
                              if (res.result.vacuumEndDate !== null)
                              {
                                   GetContainering(res.result)
                              }
                              break;
          
                         case 'Extractions':
                              if (res.result.extractionEndDate !== null)
                              {
                                   GetContainering(res.result)
                              }
                              break;
          
                         case 'Atmospheric/Vacuum':
          
                              if (res.result.vacuumEndDate !== null)
                              {
                                   GetContainering(res.result)
                              }
                              break;
                    
                         default:
                              alert(`Unrecognized experiment type ${myDistillationExp.distillationTypeName}`);
                    }
               }

            } else {
              openModalMessages("Error Loading", `There was an error loading the Distillation #${incomingExperimentID}, please try again.  ${res.message} `)
              setMyDistillationExp([])
            } 
          });
    
          return () => {
            cancelPromise = true
          }
        }
     // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [incomingExperimentID, reloadExp])

     useEffect(() => {
          let cancelPromise = false
      
               Location.getAll().then((res) => {
                  if (cancelPromise) return
                  setAllLocations(res.filter(result => result.isActive === true).sort((a, b) => a.locationName.localeCompare(b.locationName)))
               });

               UnitOfMeasure.getAll().then((res) => {
                    if (cancelPromise) return
                    setAvailableUOMs(res.filter(result => (result.metricStandardConversion !== null) && result.isActive === true).sort((a, b) => a.uoMName.localeCompare(b.uoMName)))
               });

               ContainerType.getAll().then((res) => {
                    if (cancelPromise) return
                    setContainerTypes(res.filter(result => result.isActive === true).sort((a, b) => a.name.localeCompare(b.name)))
               });

               DistillationCutType.getAllCutTypes().then((res) => {
                    if (cancelPromise) return
                    setAvailableCutTypes(res.filter(cutType => cutType.isActive === true))            
               })
                  
          return () => {
              cancelPromise = true
            }
      }, [])

     function closePrintInformation() {
          setPrintInfoOpen(false)

          if (printModalButton2Text === "View Distillations")
          {
               window.location.href = "/distillations"
          }
     }

     async function GetContainering (exp) {
          let tempContainers = []
          let tempContainerErorrs = []

          const locations  = await Location.getAll()

          for (let index = 0; index < exp.experimentCuts.length; index++) 
          {
               const testingInfo = await CreateMyTestStructure(exp.experimentCuts[index], exp);

               if (testingInfo !== null)
               {
                    const response = await Container.GetContainersToCreate(JSON.stringify(testingInfo));

                    if (response.message === 'Success')
                    {
                         let arrayContainers = []
                         let arrayContainerErrorChecks = []

                         response.result.forEach(oContainer => {

                              oContainer.locationName = null
                              oContainer.location =  locations?.find(obj => obj.locationName === exp.experimentLocationName) ?  locations.find(obj => obj.locationName === exp.experimentLocationName) : null

                              oContainer.returnLocationName = 'DISCARD'
                              oContainer.returnLocation = locations?.find(obj => obj.locationName === exp.experimentLocationName)

                              oContainer.ownerEmail = exp.requestedByEmail
                    
                              //these properties are added to keep track of the min size, in case the user changes volumes
                              oContainer._originalAmount = oContainer.currentAmount
                              oContainer._originalUoM = oContainer.uom
                              oContainer._isRetainContainer = false
                              
                              //set up the error checking on the containers          
                              arrayContainers.push(oContainer)
                              arrayContainerErrorChecks.push(structuredClone({containerType: false,
                                   size: false,
                                   currentAmount: false, 
                                   uom: false,
                                   location: oContainer.locationName === null && oContainer.location === null ? null  : false, 
                                   ownerEmail: false}))
                         });

                         tempContainers.push({cutID: exp.experimentCuts[index].id, containers:structuredClone(arrayContainers)})
                         tempContainerErorrs.push({cutID: exp.experimentCuts[index].id, errors:structuredClone(arrayContainerErrorChecks)})
                    } else {
                         openModalMessages("Error Getting Containers", `There was an error generating the testing containers, please try again.  ${response.message} `)
                    }
               }
          }

          setTestingContainerErrors(tempContainerErorrs)
          setTestingContainers(tempContainers)
     }

     async function CreateMyTestStructure (oCut, exp) {
          let myMethods

          if (oCut.testTemplateJSON === null || oCut.distillationCutStatusName === 'Failed')
          {
               return null
          } else {
               myMethods = JSON.parse(oCut.testTemplateJSON).methods
          }

          if (myMethods.length > 0)
          {
               let newTests = []
          
               for (const oMethodInfo of myMethods)
               {
                    const tests = await BuildTestingPayload(oMethodInfo, oCut.thisContainer, null, exp.chargeCode, exp.requestedByEmail)

                    if (tests === null || typeof tests === 'string' || typeof tests[0] === 'string')
                    {
                      if (Array.isArray(tests))
                      {
                        openModalMessages("Error Building Tests", tests)        
                        return
                      } else {
                        openModalMessages("Error Building Tests", `There was an error building the test payload. ${tests ? tests : ''}`)        
                        return
                      }
                    }
              
                    for (const oTest of tests)
                    {
                      newTests.push(oTest)  
                    }
               }
          
               var testingInfo = {
                    tests: newTests,
                    containeringMethod: "MIDAS",
                    requireParentContainer: true
               }

               return testingInfo;
          }
     }

     function closeModalNoButton() {
          setModalNoButtonOpen(false);
     }
      
     function openModalNoButton(title, text) {
          setModalNoButtonOpen(true);
          setModalNoButtonTitle(title);
          setModalNoButtonText(text);
     }

     function closeModalMessages() {
          setModalMessagesOpen(false);
     }
      
     function openModalMessages(title, text) {
          setModalMessagesOpen(true);
          setModalMessagesTitle(title);
          setModalMessagesText(text);
     }

     const handleCutRowChange = (property, value, cutID) => {
          const copyExp = structuredClone(myDistillationExp)
          const myIndex = myDistillationExp.experimentCuts.findIndex(cut => cut.id === cutID)

          if (myIndex >= 0)
          {
               if (property === "containerType")
               {
                    if (value === null)
                    {
                         copyExp.experimentCuts[myIndex].thisContainer[property] = containerTypes.find(type => type.copyExp.experimentCuts[myIndex].thisContainer.containerTypeName)
                    } else {
                         copyExp.experimentCuts[myIndex].thisContainer[property] = value
                    }
               } else {
                    copyExp.experimentCuts[myIndex][property] = value
               }
          }

          setMyDistillationExp(copyExp)
     }

     const updateCutErrors = (property, value, cutID) => {
          const copyErrors = structuredClone(cutErrors)
          const myIndex = cutErrors.findIndex(cut => cut.id === cutID)

          if (myIndex >= 0)
          {
               copyErrors[myIndex][property] = value
          }

          setCutErrors(copyErrors)
     }

     const handleExperimentChange = (property, value) => {
          const copyExp = structuredClone(myDistillationExp)
          copyExp[property] = value

          if (property === 'actualInitialSampleAmount')
          {
       
               switch (copyExp.distillationTypeName) {
                    case 'Atmospheric':
                         const atmosphereWholeCut = copyExp?.experimentCuts?.find(cut => cut?.thisDistillationCutType.isStartingCut === true && cut?.thisDistillationCutType.isAtmospheric === true)
                         const myIndex = copyExp.experimentCuts.findIndex(cut => cut.id === atmosphereWholeCut.id)
                         
                         copyExp.experimentCuts[myIndex].distillationCutYieldInG = value
                         break;
     
                    case 'Vacuum':
                         const vacuumWholeCut = copyExp?.experimentCuts?.find(cut => cut?.thisDistillationCutType.isStartingCut === true && cut?.thisDistillationCutType.isHighVacuum === true)
                         const vacuumIndex = copyExp.experimentCuts.findIndex(cut => cut.id === vacuumWholeCut.id)
                         
                         copyExp.experimentCuts[vacuumIndex].distillationCutYieldInG = value
                         break;
     
                    case 'Extractions':
                         //setExtractionExperimentIsStarting(true)
                         break;
     
                    case 'Atmospheric/Vacuum':
     
                         if (atmosphericExperimentHasEnded)
                         {
                              const vacuumWholeCut2 = copyExp?.experimentCuts?.find(cut => cut?.thisDistillationCutType.isStartingCut === true && cut?.thisDistillationCutType.isHighVacuum === true)
                              const vacuumIndex2 = copyExp.experimentCuts.findIndex(cut => cut.id === vacuumWholeCut2.id)
                              
                              copyExp.experimentCuts[vacuumIndex2].distillationCutYieldInG = value
                         } else {
                              const atmosphereWholeCut2 = copyExp?.experimentCuts?.find(cut => cut?.thisDistillationCutType.isStartingCut === true && cut?.thisDistillationCutType.isAtmospheric === true)
                              const myIndex2 = copyExp.experimentCuts.findIndex(cut => cut.id === atmosphereWholeCut2.id)
                              
                              copyExp.experimentCuts[myIndex2].distillationCutYieldInG = value
                         }
                         break;
               
                    default:
                         alert(`Unrecognized experiment type ${myDistillationExp.distillationTypeName}`);
               }

              
          } else if (property === 'experimentLocation')
          {
               const copyErrors = structuredClone(experimentErrors)
               copyErrors[property] = value === null
               setExperimentErrors(copyErrors)
          }

      
          setMyDistillationExp(copyExp)
     }

     const updateExperimentErrors = (property, value) => {
          const copyErrors = structuredClone(experimentErrors)

          copyErrors[property] = value

          setExperimentErrors(copyErrors)
     }

     function calculateWaterYieldPercentage_Vacuum ()
     {
          const vacuumWholeCut = myDistillationExp?.experimentCuts?.find(cut => cut?.thisDistillationCutType.isStartingCut === true && cut?.thisDistillationCutType.isHighVacuum === true)
          return +myDistillationExp.vacuumWettage / +vacuumWholeCut?.distillationCutYieldInG * 100
     }

     function calculateWaterYieldPercentage_Atmospheric ()
     {
          const atmosphereWholeCut = myDistillationExp?.experimentCuts?.find(cut => cut?.thisDistillationCutType.isStartingCut === true && cut?.thisDistillationCutType.isAtmospheric === true)
          return +myDistillationExp.atmosphericWettage / +atmosphereWholeCut?.distillationCutYieldInG * 100
     }

     function calculateWettageYieldPercentage_Vacuum ()
     {
          const vacuumWholeCut = myDistillationExp?.experimentCuts?.find(cut => cut?.thisDistillationCutType.isStartingCut === true && cut?.thisDistillationCutType.isHighVacuum === true)

          return +myDistillationExp.waterWeight / +vacuumWholeCut?.distillationCutYieldInG * 100
     }

     function calculateWettageYieldPercentage_Atmospheric ()
     {
          const atmosphereWholeCut = myDistillationExp?.experimentCuts?.find(cut => cut?.thisDistillationCutType.isStartingCut === true && cut?.thisDistillationCutType.isAtmospheric === true)
          return +myDistillationExp.waterWeight / +atmosphereWholeCut?.distillationCutYieldInG * 100
     }

     const calculateYieldPercentage = (cut) => {
          const atmosphereWholeCut = myDistillationExp?.experimentCuts?.find(cut => cut?.thisDistillationCutType.isStartingCut === true && cut?.thisDistillationCutType.isAtmospheric === true)
          const vacuumWholeCut = myDistillationExp?.experimentCuts?.find(cut => cut?.thisDistillationCutType.isStartingCut === true && cut?.thisDistillationCutType.isHighVacuum === true)
      
          if (cut?.thisDistillationCutType.isStartingCut === true || cut?.thisDistillationCutType.isBlend === true) return

          if (cut?.thisDistillationCutType?.isAtmospheric) 
          {
            return RoundNumber(+cut?.distillationCutYieldInG / +atmosphereWholeCut?.distillationCutYieldInG * 100, 3)
          } else {
            return RoundNumber(+cut?.distillationCutYieldInG / +vacuumWholeCut?.distillationCutYieldInG * 100, 3)
          }
     }

     const atmosphericMassBalance = myDistillationExp?.experimentCuts?.reduce((acc, cut) => {
          if(cut?.thisDistillationCutType.isMassBalance === true && cut?.thisDistillationCutType?.isAtmospheric)
          {
            const yieldPercentage = calculateYieldPercentage(cut)
            acc += yieldPercentage
          }
          return acc
     }, 0) + calculateWaterYieldPercentage_Atmospheric() + calculateWettageYieldPercentage_Atmospheric()
      
     const vacuumMassBalance = myDistillationExp?.experimentCuts?.reduce((acc, cut) => {
          if(cut?.thisDistillationCutType.isMassBalance === true && cut?.thisDistillationCutType?.isHighVacuum)
          {
               const yieldPercentage = calculateYieldPercentage(cut)
               acc += yieldPercentage
          }
          return acc
     }, 0) + calculateWaterYieldPercentage_Vacuum() + calculateWettageYieldPercentage_Vacuum()

     function ValidateExperiment (submitType) {
          let hasErrors = []

          if (experimentErrors.experimentLocation === true || experimentErrors.experimentLocation === null)
          {
              hasErrors.push("An experiment location must be selected.")
          }
      
          if ((experimentErrors.atmosphericStillName === true || experimentErrors.atmosphericStillName === null) && submitType === 'Atmospheric')
          {
              hasErrors.push('An atmospheric still name must be entered.')
          }
      
          if ((experimentErrors.vacuumStillName === true || experimentErrors.vacuumStillName === null) && submitType === 'Vacuum')
          {
              hasErrors.push("A vacuum still name must be entered.") 
          }
      
          if (experimentErrors.waterWeight === true || experimentErrors.waterWeight === null)
          {
              hasErrors.push('The water weight must be entered or is invalid.') 
          }
      
          if ((experimentErrors.atmosphericWettage === true || experimentErrors.atmosphericWettage === null) && submitType === 'Atmospheric')
          {
              hasErrors.push('The atmospheric wettage must be entered or is invalid.')
          }
      
          if ((experimentErrors.vacuumWettage === true || experimentErrors.vacuumWettage === null) && submitType === 'Vacuum')
          {
              hasErrors.push('The vacuum wettage must be entered or is invalid.')
          }

          if ((experimentErrors.vacuumPotSizeL === true || experimentErrors.vacuumPotSizeL === null) && submitType === 'Vacuum')
          {
              hasErrors.push('The pot size must be entered or is invalid.')
          }

          if ((experimentErrors.atmosphericPotSizeL === true || experimentErrors.atmosphericPotSizeL === null) && submitType === 'Atmospheric')
          {
              hasErrors.push('The pot size must be entered or is invalid.')
          }

          if (experimentErrors.actualInitialSampleAmount === true || experimentErrors.actualInitialSampleAmount === null)
          {
              hasErrors.push('The actual intitial sample amount must be entered or is invalid.')
          }

          if (experimentErrors.extractionUnit === true || experimentErrors.extractionUnit === null)
          {
              hasErrors.push('The extraction unit must be entered or is invalid.')
          }
      
          let containerErrors = ValidateCuts(submitType)
      
          const combindedErrors = hasErrors.concat(containerErrors)
      
          if (combindedErrors.length === 0) {
               SaveResults()      

          } else {
               openModalMessages(errorsModalTitle, combindedErrors, "Ok")
          }
     }
      
     function ValidateCuts (submitType) 
      {
          let hasErrors = []
      
          cutErrors.forEach((oCutError) => {

               const cut = myDistillationExp.experimentCuts.find(obj => obj.id === oCutError.id)

               if (cut.thisDistillationCutType.isExtraction || (cut.thisDistillationCutType.isAtmospheric && submitType === 'Atmospheric') || (cut.thisDistillationCutType.isAtmospheric && submitType === 'Vacuum'))
               {
                    if (oCutError.actualStartingTemperatureC === true || oCutError.actualStartingTemperatureC === null)
                    {
                         hasErrors.push(`Cut ${oCutError.id} has an invalid actual starting temp.`)
                    }
               
                    if (oCutError.actualEndTemperatureC === true || oCutError.actualEndTemperatureC === null)
                    {
                         hasErrors.push(`Cut ${oCutError.id} has an invalid actual end temp.`)
                    }
               
                    if (oCutError.pressure === true || oCutError.pressure === null)
                    {
                         hasErrors.push(`Cut ${oCutError.id} has an invalid pressure.`)
                    }
               
                    if (oCutError.potLiquidTemperatureC === true || oCutError.potLiquidTemperatureC === null)
                    {
                         hasErrors.push(`Cut ${oCutError.id} has an invalid pot liquid temp.`)
                    }
               
                    if (oCutError.distillationCutYieldInG === true || oCutError.distillationCutYieldInG === null)
                    {
                         hasErrors.push(`Cut ${oCutError.id} has an invalid net weight.`)
                    }

                    if (oCutError.densityYieldPercent === true || oCutError.densityYieldPercent === null)
                    {
                         hasErrors.push(`Cut ${oCutError.id} has an invalid density yield (%).`)
                    }

                    if (oCutError.riYieldPercent === true || oCutError.riYieldPercent === null)
                    {
                              hasErrors.push(`Cut ${oCutError.id} has an invalid RI yield (%).`)
                    }

                    if (oCutError.averageYieldPercent === true || oCutError.averageYieldPercent === null)
                    {
                              hasErrors.push(`Cut ${oCutError.id} has an invalid average yield (%).`)              
                    }

                    if (oCutError.carryUnderYieldPercent === true || oCutError.carryUnderYieldPercent === null)
                    {
                              hasErrors.push(`Cut ${oCutError.id} has an invalid Carry Under yield (%).`)              
                    }

                    if (oCutError.numberExtractionStages === true || oCutError.numberExtractionStages === null)
                    {
                              hasErrors.push(`Cut ${oCutError.id} has an invalid number of extraction steps.`)              
                    }

                    if (oCutError.extractSolventWeightPercent === true || oCutError.extractSolventWeightPercent === null)
                    {
                              hasErrors.push(`Cut ${oCutError.id} has an invalid extract solvent weight (%).`)              
                    }

                    if (oCutError.raffinateSolventWeightPercent === true || oCutError.raffinateSolventWeightPercent === null)
                    {
                              hasErrors.push(`Cut ${oCutError.id} has an invalid Raffinate solvent weight (%).`)              
                    }

                    if (oCutError.solventWaterWeightPercent === true || oCutError.solventWaterWeightPercent === null)
                    {
                              hasErrors.push(`Cut ${oCutError.id} has an invalid solvent water weight (%).`)              
                    }

                    if (oCutError.towerBottomTemperatureC === true || oCutError.towerBottomTemperatureC === null)
                    {
                              hasErrors.push(`Cut ${oCutError.id} has an invalid tower bottom temp (C).`)              
                    }

                    if (oCutError.towerTopTemperatureC === true || oCutError.towerTopTemperatureC === null)
                    {
                              hasErrors.push(`Cut ${oCutError.id} has an invalid tower top temp (C).`)                
                    }

                    if (oCutError.dewaxedPercent === true || oCutError.dewaxedPercent === null)
                    {
                              hasErrors.push(`Cut ${oCutError.id} has an invalid dewaxed (%).`)              
                    }

                    if (oCutError.raffinatePercent === true || oCutError.raffinatePercent === null)
                    {
                              hasErrors.push(`Cut ${oCutError.id} has an invalid Raffinate (%).`)              
                    }
               }
              
          })    
      
          return hasErrors;
     }

     function ValidateInitialAmount () {
          const initialContainerSize = myDistillationExp.initialContainer.currentAmount
          const sampleObject = myDistillationExp.initialContainer.sample
          const substanceObject = myDistillationExp.initialContainer.sample.substance
          const gramObject = availableUOMs.find(obj => obj.uoMName === 'g')
          const requestedUoMObject = availableUOMs.find(obj => obj.uoMName === myDistillationExp.initialContainer.containerSizeUoM)

          //checks to make sure it isn't blank and is a number
          if (!EvaluateRequiredNumberField(myDistillationExp.actualInitialSampleAmount))
          {
               if(myDistillationExp.actualInitialSampleAmount > ConvertUOMs(sampleObject, substanceObject, initialContainerSize, requestedUoMObject, gramObject))
               {
                    setActualAmountMessage(`Exceeds the starting container current amount of ${initialContainerSize} ${myDistillationExp.initialContainer.containerSizeUoM}`)
                    return true
               } else {
                    setActualAmountMessage("")
                    return false
               }

          } else {
               setActualAmountMessage("Only Numbers")
               return true
          }
     }

     function ValidateInfoToStartExperiment () {
          let hasErrors = []

          if (experimentErrors.experimentLocation === true || experimentErrors.experimentLocation === null)
          {
              hasErrors.push("An experiment location must be selected.")
          }

          if (experimentErrors.actualInitialSampleAmount === true || experimentErrors.actualInitialSampleAmount === null)
          {
              hasErrors.push('The actual intitial sample amount must be entered or is invalid.')
          }
      
          if (hasErrors.length === 0) {
               StartNewExperiment()      
          } else {
              openModalMessages(errorsModalTitle, hasErrors, "Ok")
          }
     }
     
     function ValidateContainers () {
          let hasErrors = []

          testingContainerErrors.forEach((oCutContainers) => {
               oCutContainers.errors.forEach((oError, index) => {      
                    if (oError.containerType === true || oError.containerType === null)
                    {
                         hasErrors.push(`A Container Type is required on all containers for cut ${oCutContainers.cutID}.`)
                    }

                    if (oError.size === true || oError.size === null)
                    {
                         hasErrors.push(`A Container Size is required/invalid on all containers for cut ${oCutContainers.cutID}.`)
                    }

                    if (oError.currentAmount === true || oError.currentAmount === null)
                    {
                         hasErrors.push(`A Container Amount is required/invalid on all containers for cut ${oCutContainers.cutID}.`)
                    }

                    if (oError.location === true || oError.location === null)
                    {
                         hasErrors.push(`A Container Initial Location is required on all containers for cut ${oCutContainers.cutID}.`)
                    }

                    if (oError.ownerEmail === true || oError.ownerEmail === null)
                    {
                         hasErrors.push(`A Container Owner Email is required/invalid on all containers for cut ${oCutContainers.cutID}.`)
                    }
               });
          })

          testingContainers.forEach((oContainerInfo, index) => {

               const cutInfo = myDistillationExp.experimentCuts.find(cut => cut.id === oContainerInfo.cutID)
               const substanceObj  = cutInfo.thisContainer.sample.substance
               const sampleObj = cutInfo.thisContainer.sample

               oContainerInfo.containers.forEach((oContainer) => {
                    if (isNumber(oContainer.currentAmount) && oContainer.uom !== null)
                    {
                         //check to make sure they didn't reduce lower than the original amount
                         if (oContainer._originalAmount && oContainer._isRetainContainer === false)
                         {
                              if (ConvertUOMs(sampleObj, substanceObj, oContainer.currentAmount, oContainer.uom, oContainer._originalUoM) < oContainer._originalAmount)
                              {
                                   hasErrors.push(`The current amount of container ${oContainer.containerNumber} (${oContainer.currentAmount} ${oContainer.uom.uoMName}) for cut ${oContainerInfo.cutID} is less than the necessary amount for testing (${oContainer._originalAmount} ${oContainer._originalUoM.uoMName}).`)
                              }
                         }
                    }
               })
          })

          //if all checks were passed add it to the collection to register
          if (hasErrors.length === 0) {
               SaveContainersAndTesting()
          } else {
               openModalMessages(errorsModalTitle, hasErrors, "Ok")
          }

     }

     function openPrintInfo(arrayContaineringInfo) {
          let arrayPrintInfo = []
          let myContainerNumbers = []
          let midasNumber = ''
      
          arrayContaineringInfo.forEach((oDistInfo) => {
               oDistInfo.containersWithTests.forEach((oContainerInfo) => {
                    midasNumber = oContainerInfo.sampleName
                    myContainerNumbers.push(oContainerInfo.containerNumber)   
               })

               arrayPrintInfo.push({
                    sampleName: midasNumber,
                    containerNumbers: myContainerNumbers,
                    includeAdditionalSampleInformation: false,
                    chemIDOnly: false,
                    printLabelSizeType: null,
                    isBlindCoded: false,
                    blindDescription: null,
                    blindSampleName: null,
                    isShelfLabel: false,
                    shelfLabelText: null,
                    includeShelfBarcode: false
                  })           
          })
            
          setPrintModalButtonTwoText("View Distillations")
          setPrintLabelInfo(arrayPrintInfo)
          setPrintInfoOpen(true)  
     }

     function openPrintInfo_AfterStartExperiment(arrayExperimentInfo) {
          let arrayPrintInfo = []
          let myContainerNumbers = []
          let midasNumber = ''
      
          arrayExperimentInfo.experimentCuts.forEach((oCut) => {

               myContainerNumbers = []
               midasNumber = oCut.thisContainer.sampleName
               myContainerNumbers.push(oCut.thisContainer.containerNumber)  

               arrayPrintInfo.push({
                    sampleName: midasNumber,
                    containerNumbers: myContainerNumbers,
                    includeAdditionalSampleInformation: false,
                    chemIDOnly: false,
                    printLabelSizeType: null,
                    isBlindCoded: false,
                    blindDescription: null,
                    blindSampleName: null,
                    isShelfLabel: false,
                    shelfLabelText: null,
                    includeShelfBarcode: false
               })     
          })

                     
            
          setPrintModalButtonTwoText("Close")
          setPrintLabelInfo(arrayPrintInfo)
          setPrintInfoOpen(true)  
     }

     function StartNewExperiment () {

          switch (myDistillationExp.distillationTypeName) {
               case 'Atmospheric':
                    setAtmosphericExperimentIsStarting(true)
                    break;

               case 'Vacuum':
                    setVacuumExperimentIsStarting(true)
                    break;

               case 'Extractions':
                    setExtractionExperimentIsStarting(true)
                    break;

               case 'Atmospheric/Vacuum':

                    if(atmosphericExperimentHasStarted)
                    {
                         setVacuumExperimentIsStarting(true)
                    } else {
                         setAtmosphericExperimentIsStarting(true)
                    }
                    break;
          
               default:
                    alert(`Unrecognized experiment type ${myDistillationExp.distillationTypeName}`);
          }

          DistillationExperiment.StartExperiment(myDistillationExp).then((res) => {
               if (res && res.message === 'Success') 
               {
                 openModalMessages('Experiment Started', `Distillation Experiment ${myDistillationExp.id} successfully started!`)

                 setReloadExp(!reloadExp)
                 openPrintInfo_AfterStartExperiment(res.result)
               } else {
                 openModalMessages('Experiment Failed to Start', ` ${res ? res.message : 'Unspecified Error'}. Contact support if you feel this is an error.`);          
               }
         
               switch (myDistillationExp.distillationTypeName) {
                    case 'Atmospheric':
                         setAtmosphericExperimentIsStarting(false)
                         break;
     
                    case 'Vacuum':
                         setVacuumExperimentIsStarting(false)
                         break;
     
                    case 'Extractions':
                         setExtractionExperimentIsStarting(false)
                         break;
     
                    case 'Atmospheric/Vacuum':
     
                         if(atmosphericExperimentHasStarted)
                         {
                              setVacuumExperimentIsStarting(false)
                         } else {
                              setAtmosphericExperimentIsStarting(false)
                         }
                         break;
               
                    default:
                         alert(`Unrecognized experiment type ${myDistillationExp.distillationTypeName}`);
               }
          });
     }

     function SaveResults () {

          switch (myDistillationExp.distillationTypeName) {
               case 'Atmospheric':
                    setAtmosphericExperimentResultsAreSaving(true)
                    break;

               case 'Vacuum':
                    setVacuumExperimentResultsAreSaving(true)
                    break;

               case 'Extractions':
                    setExtractionExperimentResultsAreSaving(true)
                    break;

               case 'Atmospheric/Vacuum':
                    if(atmosphericExperimentHasStarted)
                    {
                         setVacuumExperimentResultsAreSaving(true)
                    } else {
                         setAtmosphericExperimentResultsAreSaving(true)
                    }
                    break;
          
               default:
                    alert(`Unrecognized experiment type ${myDistillationExp.distillationTypeName}`);
          }
               
          DistillationExperiment.SubmitExperimentResults(myDistillationExp).then((res) => {

               if (res.message === 'Success') {
                    openModalMessages('Results Saved', `Experiment Results saved successfully!`)

                    setReloadExp(!reloadExp)
               } else {
                    if (res.message)
                    {
                         openModalMessages('Results Failed to Save', `${res ? res.message : ''}. Contact support if you feel this is an error.`);
                    } else {
                         openModalMessages('Results Failed to Save', `Unspecified Error, Contact support if you feel this is an error.`);
                    }
               }

               switch (myDistillationExp.distillationTypeName) {
                    case 'Atmospheric':
                         setAtmosphericExperimentResultsAreSaving(false)
                         break;

                    case 'Vacuum':
                         setVacuumExperimentResultsAreSaving(false)
                         break;

                    case 'Extractions':
                         setExtractionExperimentResultsAreSaving(false)
                         break;

                    case 'Atmospheric/Vacuum':
                         if(atmosphericExperimentHasStarted)
                         {
                              setVacuumExperimentResultsAreSaving(false)
                         } else {
                              setAtmosphericExperimentResultsAreSaving(false)
                         }
                         break;
               
                    default:
                         alert(`Unrecognized experiment type ${myDistillationExp.distillationTypeName}`);
               }
          });
     }

     function SaveContainersAndTesting () {     
          switch (myDistillationExp.distillationTypeName) {
               case 'Atmospheric':
                    setAtmosphericExperimentTestsAreSaving(true)
                    break;

               case 'Vacuum':
                    setVacuumExperimentTestsAreSaving(true)
                    break;

               case 'Extractions':
                    setExtractionExperimentTestsAreSaving(true)
                    break;

               case 'Atmospheric/Vacuum':
                    if(atmosphericExperimentHasEnded)
                    {
                         setVacuumExperimentTestsAreSaving(true)
                    } else {
                         setAtmosphericExperimentTestsAreSaving(true)
                    }
                    break;
          
               default:
                    alert(`Unrecognized experiment type ${myDistillationExp.distillationTypeName}`);
          }

          let submissionDTO = []

          testingContainers.forEach((oContainerInfo) => {
               let tempContainersToCreate = []

               oContainerInfo.containers.forEach((oContainer, index) => {      
                   tempContainersToCreate.push(oContainer)
               });

               submissionDTO.push({experimentID: myDistillationExp.id, experimentCutID: oContainerInfo.cutID, containersWithTests: tempContainersToCreate})
          })

          DistillationExperiment.SubmitExperimentContainersAndTests(submissionDTO).then((res) => {
               if (res && res.message === "Success") 
               {
                    if (res && res.message === 'Success')
                    {
                         openPrintInfo(res.result)
                    } else {
                         if (res.message)
                         {
                              openModalMessages("Containers/Tests Failed to Save", `${res.message}. Contact support if you feel this is an error.`, "Ok");
                         } else {
                              openModalMessages('Containers/Tests Failed to Save', `Unspecified Error, Contact support if you feel this is an error.`);
                         }
                    }
               }               

               switch (myDistillationExp.distillationTypeName) {
                    case 'Atmospheric':
                         setAtmosphericExperimentTestsAreSaving(false)
                         break;
     
                    case 'Vacuum':
                         setVacuumExperimentTestsAreSaving(false)
                         break;
     
                    case 'Extractions':
                         setExtractionExperimentTestsAreSaving(false)
                         break;
     
                    case 'Atmospheric/Vacuum':
                         if(atmosphericExperimentHasEnded)
                         {
                              setVacuumExperimentTestsAreSaving(false)
                         } else {
                              setAtmosphericExperimentTestsAreSaving(false)
                         }
                         break;
               
                    default:
                         alert(`Unrecognized experiment type ${myDistillationExp.distillationTypeName}`);
               }
          })
     }

     function UpdateCutStatus (cutID) {
          if (cutID)
          {
               DistillationCut.changeStatusToFailed(cutID).then((res) => {

                    if (res && res.message === 'Success')
                    {
                         openModalMessages("Cut Status Updated", "Cut status updated to failed.", "Ok");

                         setReloadExp(!reloadExp)
                    } else {
                         if (res.message)
                         {
                              openModalMessages("Cut Status Failed to Update", `${res.message}. Contact support if you feel this is an error.`, "Ok");
                         } else {
                              openModalMessages('Cut Status Failed to Update', `Unspecified Error, Contact support if you feel this is an error.`);
                         }
                    }
               })

          }
     }
      
     const RenderCuts = () =>
     {
          switch (myDistillationExp.distillationTypeName) 
          {
               case 'Atmospheric':
                    return(
                         <>
                              <AtmosphericDistillation
                                   myDistillationExp={myDistillationExp}
                                   cutErrors={cutErrors}
                                   handleCutRowChange={handleCutRowChange}
                                   updateCutErrors={updateCutErrors}
                                   handleExperimentChange={handleExperimentChange} 
                                   experimentErrors={experimentErrors}
                                   updateExperimentErrors={updateExperimentErrors}
                                   atmosphericMassBalance={atmosphericMassBalance}
                                   calculateYieldPercentage={calculateYieldPercentage}
                                   experimentHasStarted={atmosphericExperimentHasStarted}
                                   StartExperiment={ValidateInfoToStartExperiment}
                                   experimentIsStarting={atmosphericExperimentIsStarting}
                                   allLocations={allLocations}
                                   ValidateInitialAmount={ValidateInitialAmount}
                                   actualAmountMessage={actualAmountMessage}
                                   testingContainers={testingContainers}
                                   setTestingContainers={setTestingContainers}
                                   testingContainerErrors={testingContainerErrors}
                                   setTestingContainerErrors={setTestingContainerErrors}
                                   experimentResultsEntered={atmosphericExperimentResultsEntered}
                                   fnSaveResults={ValidateExperiment}
                                   resultsAreSaving={atmosphericExperimentResultsAreSaving}
                                   fnSubmitTesting={ValidateContainers}
                                   testsAreSaving={atmosphericExperimentTestsAreSaving}
                                   experimentIsClosed={myDistillationExp.distillationStatusName === 'Completed' || myDistillationExp.distillationStatusName === 'Cancelled'}
                                   containerTypes={containerTypes}
                                   updateCutStatus={UpdateCutStatus}
                                   cutTypes={availableCutTypes}
                              ></AtmosphericDistillation>
                         </>
                    )

               case 'Vacuum':
                    return(
                         <>
                              <VacuumDistillation
                                   myDistillationExp={myDistillationExp}
                                   cutErrors={cutErrors}
                                   handleCutRowChange={handleCutRowChange}
                                   updateCutErrors={updateCutErrors}
                                   handleExperimentChange={handleExperimentChange} 
                                   experimentErrors={experimentErrors}
                                   updateExperimentErrors={updateExperimentErrors}
                                   vacuumMassBalance={vacuumMassBalance}
                                   calculateYieldPercentage={calculateYieldPercentage}
                                   experimentHasStarted={vacuumExperimentHasStarted}
                                   StartExperiment={ValidateInfoToStartExperiment}
                                   experimentIsStarting={vacuumExperimentIsStarting}
                                   allLocations={allLocations}
                                   ValidateInitialAmount={ValidateInitialAmount}
                                   actualAmountMessage={actualAmountMessage}
                                   atmosphericExperimentHasEnded={atmosphericExperimentHasEnded}
                                   availableUOMs={availableUOMs}
                                   testingContainers={testingContainers}
                                   setTestingContainers={setTestingContainers}
                                   testingContainerErrors={testingContainerErrors}
                                   setTestingContainerErrors={setTestingContainerErrors}
                                   experimentResultsEntered={vacuumExperimentResultsEntered}
                                   fnSaveResults={ValidateExperiment}
                                   resultsAreSaving={vacuumExperimentResultsAreSaving}
                                   fnSubmitTesting={ValidateContainers}
                                   testsAreSaving={vacuumExperimentTestsAreSaving}
                                   experimentIsClosed={myDistillationExp.distillationStatusName === 'Completed' || myDistillationExp.distillationStatusName === 'Cancelled'}
                                   containerTypes={containerTypes}
                                   updateCutStatus={UpdateCutStatus}
                                   cutTypes={availableCutTypes}
                              ></VacuumDistillation>
                         </>
                    )

               case 'Atmospheric/Vacuum':
                    return(
                         <>
                              <AtmosphericDistillation
                                   myDistillationExp={myDistillationExp}
                                   cutErrors={cutErrors}
                                   handleCutRowChange={handleCutRowChange}
                                   updateCutErrors={updateCutErrors}
                                   handleExperimentChange={handleExperimentChange} 
                                   experimentErrors={experimentErrors}
                                   updateExperimentErrors={updateExperimentErrors}
                                   atmosphericMassBalance={atmosphericMassBalance}
                                   calculateYieldPercentage={calculateYieldPercentage}
                                   experimentHasStarted={atmosphericExperimentHasStarted}
                                   StartExperiment={ValidateInfoToStartExperiment}
                                   experimentIsStarting={atmosphericExperimentIsStarting}
                                   allLocations={allLocations}
                                   ValidateInitialAmount={ValidateInitialAmount}
                                   actualAmountMessage={actualAmountMessage}
                                   testingContainers={testingContainers}
                                   setTestingContainers={setTestingContainers}
                                   testingContainerErrors={testingContainerErrors}
                                   setTestingContainerErrors={setTestingContainerErrors}
                                   experimentResultsEntered={atmosphericExperimentResultsEntered}
                                   fnSaveResults={ValidateExperiment}
                                   resultsAreSaving={atmosphericExperimentResultsAreSaving}
                                   fnSubmitTesting={ValidateContainers}
                                   testsAreSaving={atmosphericExperimentTestsAreSaving}
                                   experimentIsClosed={myDistillationExp.distillationStatusName === 'Completed' || myDistillationExp.distillationStatusName === 'Cancelled'}
                                   containerTypes={containerTypes}
                                   updateCutStatus={UpdateCutStatus}
                                   cutTypes={availableCutTypes}
                              ></AtmosphericDistillation>

                              <VacuumDistillation
                                   myDistillationExp={myDistillationExp}
                                   cutErrors={cutErrors}
                                   handleCutRowChange={handleCutRowChange}
                                   updateCutErrors={updateCutErrors}
                                   handleExperimentChange={handleExperimentChange} 
                                   experimentErrors={experimentErrors}
                                   updateExperimentErrors={updateExperimentErrors}
                                   vacuumMassBalance={vacuumMassBalance}
                                   calculateYieldPercentage={calculateYieldPercentage}
                                   experimentHasStarted={vacuumExperimentHasStarted}
                                   StartExperiment={ValidateInfoToStartExperiment}
                                   experimentIsStarting={vacuumExperimentIsStarting}
                                   allLocations={allLocations}
                                   ValidateInitialAmount={ValidateInitialAmount}
                                   actualAmountMessage={actualAmountMessage}
                                   atmosphericExperimentHasEnded={atmosphericExperimentHasEnded}
                                   availableUOMs={availableUOMs}
                                   testingContainers={testingContainers}
                                   setTestingContainers={setTestingContainers}
                                   testingContainerErrors={testingContainerErrors}
                                   setTestingContainerErrors={setTestingContainerErrors}
                                   experimentResultsEntered={vacuumExperimentResultsEntered}
                                   fnSaveResults={ValidateExperiment}
                                   resultsAreSaving={vacuumExperimentResultsAreSaving}
                                   fnSubmitTesting={ValidateContainers}
                                   testsAreSaving={vacuumExperimentTestsAreSaving}
                                   experimentIsClosed={myDistillationExp.distillationStatusName === 'Completed' || myDistillationExp.distillationStatusName === 'Cancelled'}
                                   containerTypes={containerTypes}
                                   updateCutStatus={UpdateCutStatus}
                                   cutTypes={availableCutTypes}
                              ></VacuumDistillation>
                         
                         </>
                   )

               case 'Extractions':
                    return(
                         <ExtractionDistillation
                              myDistillationExp={myDistillationExp}
                              cutErrors={cutErrors}
                              handleCutRowChange={handleCutRowChange}
                              updateCutErrors={updateCutErrors}
                              handleExperimentChange={handleExperimentChange} 
                              experimentErrors={experimentErrors}
                              updateExperimentErrors={updateExperimentErrors}
                              experimentHasStarted={extractionExperimentHasStarted}
                              StartExperiment={ValidateInfoToStartExperiment}
                              experimentIsStarting={extractionExperimentIsStarting}
                              allLocations={allLocations}
                              ValidateInitialAmount={ValidateInitialAmount}
                              actualAmountMessage={actualAmountMessage}
                              testingContainers={testingContainers}
                              setTestingContainers={setTestingContainers}
                              testingContainerErrors={testingContainerErrors}
                              setTestingContainerErrors={setTestingContainerErrors}
                              experimentResultsEntered={extractionExperimentResultsEntered}
                              fnSaveResults={ValidateExperiment}
                              resultsAreSaving={extractionExperimentResultsAreSaving}
                              fnSubmitTesting={ValidateContainers}
                              testsAreSaving={extractionExperimentTestsAreSaving}
                              experimentIsClosed={myDistillationExp.distillationStatusName === 'Completed' || myDistillationExp.distillationStatusName === 'Cancelled'}
                              containerTypes={containerTypes}
                              updateCutStatus={UpdateCutStatus}
                              cutTypes={availableCutTypes}
                         ></ExtractionDistillation>
                    )
     
                    
               default:
                    break;
          }
     }

  return (
     <div>

     <span className='pageheader'>{"Distillation Results"}</span>

     {RenderCuts()}

     {/* Informational Messages */}
     <ModalNoButton title={modalNoButtonTitle} open={modalNoButtonOpen} setOpen={setModalNoButtonOpen}>
          <label>
          {modalNoButtonText}
          </label>
     </ModalNoButton>

     {/* Informational Messages */}
     <ModalSimpleButton title={modalMessagesTitle} buttonText={modalMessagesButtonText} buttonAction={closeModalMessages} open={modalMessagesOpen} setOpen={setModalMessagesOpen}>
          {Array.isArray(modalMessagesText) ?
          (modalMessagesText && modalMessagesText.map((text, index) => {
          return (
               <div style={{display:"flex"}} key={`myBlendingErrorChecks${index}`}>
                    <label>
                    {text}
                    </label>
               </div>
          )
          }))
          :
          ( <label>
          {modalMessagesText}
          </label>
          )}      
     </ModalSimpleButton>

     <PrintInformation 
          open={printInfoOpen} 
          setOpen={setPrintInfoOpen} 
          button2Action={closePrintInformation} 
          button2Text={printModalButton2Text}
          printLabelInfo={printLabelInfo}
          isShelfLabel={false}
          > 
     </PrintInformation>

     </div>
);
};

  export default StartExperiment;