import React from "react";
import { Box } from "@mui/material";
import SubmitSDS from "./SubmitSDS";
import { GlobalTabCollection, StyledTab } from "../../styles";
import ProcessSDS from "./ProcessSDS";

const SDS = ({ initialTabValue = 0 }) => {
  const [tabValue, setTabValue] = React.useState(initialTabValue);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderSearchTab = () => {
    switch (tabValue) {
      case 0: {
        return <SubmitSDS />;
      }
      case 1: {
        return <ProcessSDS />;
      }
      default: {
        alert(tabValue);
      }
    }
  };
  return (   
      <div>
        <Box sx={{ bgcolor: "#fff", pb: 1 }}>
          <GlobalTabCollection
            value={tabValue}
            onChange={handleChange}
            aria-label="ant example"
          >
            <StyledTab label="Submit SDS" />
            <StyledTab
              label="Process SDS"
            />
          </GlobalTabCollection>
        </Box>
        {renderSearchTab()}
      </div>
  );
};

export default SDS;
